import React from "react";
import {Box, createStyles, makeStyles, Theme, Typography,} from "@material-ui/core";
import {DinnerSeating} from "../../models/models";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      flex: 1,
      backgroundColor: theme.palette.background.paper,
      display: "flex",
      flexDirection: "column",
      overflow: "auto",
    },
    seat: {
      flex: 1,
      padding: 10,
      borderRadius: 10,
      border: `2px solid ${theme.palette.background.default}`,
      height: 40,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    businessPartnerSeat: {
      flex: 1,
      padding: 10,
      borderRadius: 10,
      border: `2px solid ${theme.palette.primary.main}`,
      height: 40,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    seatRow: {
      display: "flex",
    },
    placeholder: {
      flex: 1,
      padding: 10,
      borderRadius: 10,
      height: 40,
      border: `2px dashed ${theme.palette.background.default}`,
    },
  })
);

export interface DinnerTableProps {
  dinnerSeatings: DinnerSeating[];
  focusedRegistrationID: string;
  focusedBusinessPartnerID: string;
  focusedTableID: number;
  tableLength?: number;
  tableWidth?: number;
}

export const DinnerTable: React.FC<DinnerTableProps> = ({
  dinnerSeatings,
  focusedRegistrationID,
  focusedBusinessPartnerID,
  focusedTableID,
  tableLength = 3,
  tableWidth = 2,
}) => {
  const classes = useStyles();

  const seats: any[][] = [];

  let focusedSeatID: number = 0;



  dinnerSeatings.forEach((dinnerSeating) => {
    if (dinnerSeating.registrationID === focusedRegistrationID) {
      focusedSeatID = dinnerSeating.seatID;
    }

    const row =
      seats[dinnerSeating.sideOfTable] || Array(tableLength).fill(null);


    row[dinnerSeating.seatID] = dinnerSeating;


    seats[dinnerSeating.sideOfTable] = row;
  });


  function add(x: number,y: number) {
    return x+y;
  }

  function setSeatBox(seat: any, focusedRegistrationID: string, seatIndex: number, rowIndex: number) {

    if(seat.type === "Executive Registration" && seat.tableID === focusedTableID && seat.isSeated){
//standard executive registration
      return (
        <Box
          key={seatIndex}
          style={{
            marginTop: rowIndex > 0 ? 10 : 0,
            marginLeft: seatIndex > 0 ? 10 : 0,
          }}
          className= {
            (focusedRegistrationID === seat.registrationID)
                  ? classes.businessPartnerSeat
                  : classes.seat}
        >

          <Box>
            <Typography color="textSecondary" align="center">
              {(focusedRegistrationID === seat.registrationID)
                  ? "You"
                  : seat.registrationName}
            </Typography>
          </Box>
          <Box>
            <Typography color="textSecondary" align="center">
                  {seat.seatNumber}
            </Typography>
          </Box>
         </Box>
       );
     }
     else if(focusedBusinessPartnerID === "" && seat.isSeated){
//business partner seat
      return (
        <Box
          key={seatIndex}
          style={{
            marginTop: rowIndex > 0 ? 10 : 0,
            marginLeft: seatIndex > 0 ? 10 : 0,
          }}
          className= {classes.seat}
        >
          <Box>
            <Typography color="textSecondary" align="center">
              {(focusedRegistrationID === seat.registrationID)
                  ? "You"
                  : seat.registrationName}
            </Typography>
            </Box>
          <Box>
            <Typography color="textSecondary" align="center">
                  {seat.seatNumber}
            </Typography>
          </Box>
         </Box>
       );
     }
     else if(focusedBusinessPartnerID === seat.registrationBusinessPartner  && seat.isSeated){
       return (
         <Box
           key={seatIndex}
           style={{
             marginTop: rowIndex > 0 ? 10 : 0,
             marginLeft: seatIndex > 0 ? 10 : 0,
            }}
            className= {classes.businessPartnerSeat}
          >
          <Box>
            <Typography color="textSecondary" align="center">
                {(focusedRegistrationID === seat.registrationID)
                  ? "You"
                  : seat.registrationName}
            </Typography>
          </Box>
          <Box>
            <Typography color="textSecondary" align="center">
                {seat.seatNumber}
            </Typography>
          </Box>
          </Box>
        );
     }
     else {
       return (
         <Box
           style={{
             marginTop: rowIndex > 0 ? 10 : 0,
             marginLeft: seatIndex > 0 ? 10 : 0,
           }}
           className={classes.placeholder}
          />
        );
      };
    }

  return (
    <Box className={classes.container}>
      {seats.map((seatRow, rowIndex) => {
        let startSeatIndexOffset = -1;
        let endSeatIndexOffset = 0;
        if (focusedSeatID === 0) {
          startSeatIndexOffset = 0;
        }
        else {
          startSeatIndexOffset = 1;
        };
        let slicedSeatRow = seatRow.slice(focusedSeatID - startSeatIndexOffset, focusedSeatID + 2);

        if (slicedSeatRow.length < tableLength) {
          slicedSeatRow = [
            ...slicedSeatRow,
            ...Array(tableLength - slicedSeatRow.length).fill(null),
          ];
        }

        return (
          <Box key={rowIndex} className={classes.seatRow}>
            {slicedSeatRow.map((seat, seatIndex) =>
              seat ? (
                setSeatBox(seat, focusedRegistrationID, seatIndex, rowIndex)
              ) : (
                <Box
                  style={{
                    marginTop: rowIndex > 0 ? 10 : 0,
                    marginLeft: seatIndex > 0 ? 10 : 0,
                  }}
                  className={classes.placeholder}
                />
              )
            )}
          </Box>
        );
      })}
    </Box>
  );
};
