import React, {useEffect, useMemo} from "react";
import {Box,
        createStyles,
        IconButton,
        makeStyles,
        Theme,
        Typography,
        LinearProgress,
      } from "@material-ui/core";
import MapRoundedIcon from "@material-ui/icons/MapRounded";
import EventSeatRoundedIcon from "@material-ui/icons//EventSeatRounded";
import {useDispatch, useSelector} from "react-redux";
import {FormattedDate} from "react-intl";
import {AgendaElement, AgendaElementType, SeatedThinkTank, SeatedThinkTankRealTable,} from "../../models/models";
import Config from "../../config";
import QRCode from "qrcode.react";
import {retrieveRegistrations, selectRegistration, selectRegistrations,} from "../registrations/registrationsSlice";
import {retrieveProfile, selectProfile} from "../registrations/registrationsSlice";
import {retrieveEvent, selectEvent} from "./eventSlice";
import {selectThinkTankSeatingIsFetching, retrieveThinkTankSeating, selectThinkTankSeatings, selectThinkTanks, selectThinkTankRealTables} from "./thinkTankSeatingSlice";
import {selectToken} from "../authentication/authenticationSlice";
import {getClosestAgendaElementToDate} from "../../utils/agenda";
import {retrieveDinnerSeating, selectDinnerSeating, selectDinnerSeatingIsFetching} from "./dinnerSeatingSlice";
import {FirstLoginFlowDialog} from "../firstLoginFlow/FirstLoginFlowDialog"


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      backgroundColor: theme.palette.background.default,
      overflow: "auto",
    },
    focusArea: {
      backgroundColor: theme.palette.primary.main,
      height: 200,
      padding: 20,
    },
    row: {
      display: "flex",
      alignItems: "center",
      marginBottom: 20,
    },
    indicator: {
      height: 10,
      width: 10,
      borderRadius: 5,
      backgroundColor: theme.palette.primary.main,
      marginRight: 10,
    },
    name: {
      fontWeight: "bold",
    },
  })
);

export const Badge = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const authToken = useSelector(selectToken);

  const event = useSelector(selectEvent);
  const registration = useSelector(selectRegistration(Config.getInstance().getRegistrationID()));
  const registrations = useSelector(selectRegistrations);
  const profile = useSelector(selectProfile(Config.getInstance().getRegistrationID()));
  const seatedRegistrations = useSelector(selectThinkTankSeatings);

  useEffect(() => {
    if (authToken) {
      dispatch(retrieveEvent(authToken, Config.getInstance().getEventID()));
      dispatch(retrieveRegistrations(authToken, Config.getInstance().getEventID()));
      dispatch(retrieveThinkTankSeating(authToken, Config.getInstance().getEventID()));
      dispatch(retrieveDinnerSeating(authToken, Config.getInstance().getEventID()));
      dispatch(retrieveProfile(authToken,Config.getInstance().getEventID(),Config.getInstance().getRegistrationID()));
    }
  }, [authToken]); // eslint-disable-line react-hooks/exhaustive-deps

  const dinnerSeatingIsFetching = useSelector(selectDinnerSeatingIsFetching);
  const thinkTankSeatingIsFetching = useSelector(selectThinkTankSeatingIsFetching);

  const roundOneDinnerSeating = useSelector(
    selectDinnerSeating(Config.getInstance().getRegistrationID(), 0)
  );
  const roundTwoDinnerSeating = useSelector(
    selectDinnerSeating(Config.getInstance().getRegistrationID(), 1)
  );

  const thinkTankSeatings: ThinkTankSeating[] = useMemo(() => {
    const userSeatings = seatedRegistrations.filter(
      (registration) =>
        registration.profile.id === Config.getInstance().getRegistrationID()
    );
    return userSeatings.sort((a: ThinKTankSeating, b: ThinkTankSeating) =>
      a.round > b.round ? 1 : a.round < b.round ? -1 : 0
    );
  }, [seatedRegistrations.length]); // eslint-disable-line react-hooks/exhaustive-deps


  const thinkTankRoundOneDetail = (
    <Box style={{ padding: 20 }}>
    {thinkTankSeatings.length > 0 &&
    <>
      <Typography
        variant="h6"
        color="textPrimary"
        style={{ fontWeight: "bold" }}
      >
        {thinkTankSeatings[0]?.thinkTankRealTable?.thinkTankTable === null ? "Not attending in current round" : thinkTankSeatings[0]?.thinkTankRealTable?.thinkTankTable?.title}
      </Typography>
      <Typography
        variant="h6"
        color="textPrimary"
        style={{ fontWeight: "bold" }}
      >
        {thinkTankSeatings[0]?.thinkTankRealTable?.thinkTankTable === null ? "" : "Table Nr. " + thinkTankSeatings[0]?.thinkTankRealTable?.tableNumber}

      </Typography>
</>
}
    </Box>
  );

  const keynoteRoundOneDetail = (
    <Box style={{ padding: 20 }}>
    {thinkTankSeatings.length > 0 &&
      <Typography
        variant="h6"
        color="textPrimary"
        style={{ fontWeight: "bold" }}
      >
        {thinkTankSeatings[0]?.thinkTankRealTable?.thinkTankTable === null ? "" : "Table Nr. " + thinkTankSeatings[0]?.thinkTankRealTable?.tableNumber}

      </Typography>
    }
    </Box>
  );

  const thinkTankRoundTwoDetail = (
    <Box style={{ padding: 20 }}>
    {thinkTankSeatings.length > 1 &&
    <>
      <Typography
        variant="h6"
        color="textPrimary"
        style={{ fontWeight: "bold" }}
      >
        {thinkTankSeatings[1]?.thinkTankRealTable?.thinkTankTable === null ? "Not attending in current round" : thinkTankSeatings[1]?.thinkTankRealTable?.thinkTankTable?.title}
      </Typography>
      <Typography
        variant="h6"
        color="textPrimary"
        style={{ fontWeight: "bold" }}
      >
        {thinkTankSeatings[1]?.thinkTankRealTable?.thinkTankTable === null ? "" : "Table Nr. " + thinkTankSeatings[1]?.thinkTankRealTable?.tableNumber}

      </Typography>
</>
}
    </Box>
  );

  const keynoteRoundTwoDetail = (
    <Box style={{ padding: 20 }}>
    {thinkTankSeatings.length > 1 &&
      <Typography
        variant="h6"
        color="textPrimary"
        style={{ fontWeight: "bold" }}
      >
        {thinkTankSeatings[1]?.thinkTankRealTable?.thinkTankTable === null ? "" : "Table Nr. " + thinkTankSeatings[1]?.thinkTankRealTable?.tableNumber}

      </Typography>
}
    </Box>
  );

  const dinnerRoundOneDetail = (
    <Box
      style={{
        padding: 20,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {roundOneDinnerSeating?.isSeated ?
      <Typography variant="h1" color="textPrimary" style={{ marginRight: 20 }}>
          {roundOneDinnerSeating?.seatNumber || 0}
      </Typography>
      :
      <Typography variant="h6" color="textPrimary">
          Not attending in current round
      </Typography>
      }
      {roundOneDinnerSeating?.isSeated &&
      <EventSeatRoundedIcon
        style={{ height: 80, width: 80, color: "#ffffff" }}
      />
    }
    </Box>
  );

  const dinnerRoundTwoDetail = (
    <Box
      style={{
        padding: 20,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {roundTwoDinnerSeating?.isSeated ?
      <Typography variant="h1" color="textPrimary" style={{ marginRight: 20 }}>
          {roundTwoDinnerSeating?.seatNumber || 0}
      </Typography>
      :
      <Typography variant="h6" color="textPrimary">
          Not attending in current round
      </Typography>
      }
      {roundTwoDinnerSeating?.isSeated &&
      <EventSeatRoundedIcon
        style={{ height: 80, width: 80, color: "#ffffff" }}
      />
    }
    </Box>
  );

  const isLoading = dinnerSeatingIsFetching || thinkTankSeatingIsFetching;

  const agenda: AgendaElement[] = [
    new AgendaElement(
      "1",
      AgendaElementType.KeynoteRound,
      "1st Keynote Presentation",
      "",
      "2021-11-03T14:30:00.000Z",
      "2021-11-03T15:00:00.000Z",
      keynoteRoundOneDetail,
    ),
    new AgendaElement(
      "1",
      AgendaElementType.ThinkTankRound,
      "Focus Discussion Round 1",
      "",
      "2021-11-03T15:00:00.000Z",
      "2021-11-03T16:10:00.000Z",
      thinkTankRoundOneDetail
    ),
    new AgendaElement(
      "1",
      AgendaElementType.KeynoteRound,
      "2nd Keynote Presentation",
      "",
      "2021-11-03T16:30:00.000Z",
      "2021-11-03T17:00:00.000Z",
      keynoteRoundTwoDetail,
    ),
    new AgendaElement(
      "1",
      AgendaElementType.ThinkTankRound,
      "Focus Discussion Round 2",
      "",
      "2021-11-03T17:00:00.000Z",
      "2021-11-03T18:10:00.000Z",
      thinkTankRoundTwoDetail
    ),
    new AgendaElement(
      "1",
      AgendaElementType.DinnerRound,
      "Dinner Round 1",
      "",
      "2021-11-03T18:30:00.000Z",
      "2021-11-03T19:10:00.000Z",
      dinnerRoundOneDetail
    ),
    new AgendaElement(
      "1",
      AgendaElementType.DinnerRound,
      "Dinner Round 2",
      "",
      "2021-11-03T19:25:00.000Z",
      "2021-11-03T20:05:00.000Z",
      dinnerRoundTwoDetail
    ),
  ];

//debug
//  const testDate = new Date("2022-09-30T19:00:00.000Z");
//  console.log('testDate', testDate)

  const nextRelevantAgendaElement = useMemo(() => {
    return getClosestAgendaElementToDate(
      agenda,
      new Date(),
//debug
//      testDate,
      new Date(event?.eventDate || "")
    );
  }, [seatedRegistrations?.length, event?.eventDate]); // eslint-disable-line react-hooks/exhaustive-deps
//update nextRelevatnAgendaElement also when seatedRegistrations.lenght changed
//in order that proper seating is being displayed also when login via QR code


  const getNextUpDescription = (agendaElement: AgendaElement): string => {
    if (agendaElement.type === AgendaElementType.ThinkTankRound) {
      return "Your topic for " + agendaElement.name;
    }

    if (agendaElement.type === AgendaElementType.KeynoteRound) {
      return "Your table for the " + agendaElement.name;
    }

    if (agendaElement.type === AgendaElementType.DinnerRound) {
      return "Your seat for " + agendaElement.name;
    }

    return "";
  };


  return (

    <Box className={classes.container}>

      <FirstLoginFlowDialog
           authToken={authToken || ""}
           registrations={registrations}
           profile={profile}
           event={event}
      />

      <Box style={{ padding: 20 }}>
        <Typography
          variant="h6"
          color="textPrimary"
          align="left"
          style={{ fontWeight: "bold" }}
        >
          {registration?.name}
        </Typography>
        <Typography
          color="textSecondary"
          align="left"
          style={{ fontWeight: "bold" }}
        >
          {registration?.title}
        </Typography>
        <Typography color="textSecondary" align="left">
          {registration?.company}
        </Typography>
        <Box style={{ height: 40 }} />
        <Typography color="textPrimary" align="left">
          {event?.name?.split("#")[0].trim()}
        </Typography>
        <Typography color="textSecondary" align="left">
          <FormattedDate value={event?.eventDate} />
        </Typography>
      </Box>
       {isLoading && <LinearProgress color="primary" />}
       {!isLoading &&
      <Box className={classes.focusArea}>
        <Typography color="textSecondary">
          {nextRelevantAgendaElement
            ? getNextUpDescription(nextRelevantAgendaElement)
            : ""}
        </Typography>
        {nextRelevantAgendaElement?.detailComponent}
      </Box>
    }
      <Box
        style={{
          paddingTop: 20,
          paddingLeft: 20,
          paddingRight: 20,
          paddingBottom: 40,
        }}
      >
        <Box style={{ display: "flex", alignItems: "center" }}>
          <Box style={{ flex: 1 }}>
            <Typography
              color="textPrimary"
              align="left"
              style={{ fontWeight: "bold" }}
            >
              Event Location
            </Typography>
            <Typography color="textSecondary" align="left">
              {`${event?.venueName}, ${event?.venueStreetAddress1}, ${event?.venuePostalCode} ${event?.venueCity}`}
            </Typography>
          </Box>
          <IconButton
            href={`https://www.google.de/maps/search/${event?.venueName}, ${event?.venueStreetAddress1}, ${event?.venuePostalCode} ${event?.venueCity}`}
            target="_blank"
          >
            <MapRoundedIcon />
          </IconButton>
        </Box>
        <Box style={{ height: 20 }} />
        {/*}
        <Typography
          color="textPrimary"
          align="left"
          style={{ fontWeight: "bold" }}
        >
          Venue
        </Typography>
        <Typography color="textSecondary" align="left">
          {`${event?.roomName || "-"}`}
        </Typography>
        */}
        <Box style={{ height: 20 }} />
        {thinkTankSeatings?.map((thinkTankSeating, index) => (
          thinkTankSeating.thinkTankRealTable.thinkTankTable !== null &&
          <>
            <Typography
              color="textPrimary"
              align="left"
              style={{ fontWeight: "bold" }}
            >
              {`Focus Discussion Round ${index + 1}`}
            </Typography>
            <Typography color="textSecondary" align="left">
              {thinkTankSeating.thinkTankRealTable.thinkTankTable.title}
            </Typography>
            <Typography color="textSecondary" align="left">
              Table Nr. {thinkTankSeating.thinkTankRealTable.tableNumber}
            </Typography>
            <Box style={{ height: 20 }} />
          </>
        ))}
      </Box>
      <Box style={{ flex: 1 }} />
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#f5f6fa",
          padding: 20,
        }}
      >
        <QRCode
          value={`${JSON.stringify({
            id: registration?.id,
            name: registration?.name,
            company: registration?.company,
            title: registration?.title,
          })}`}
          size={window.screen.width - 40 > 400 ? 400 : window.screen.width - 40}
          style={{ width:"90%", height: "auto", maxWidth: 400}}
          bgColor={"#f5f6fa"}
          fgColor={"#25262E"}
          includeMargins={true}
        />
      </Box>
    </Box>
  );
};
