import React from "react";
import {Box, createStyles, makeStyles, Theme, Typography,} from "@material-ui/core";
import {DinnerSeating} from "../../models/models";
import {DinnerTable} from "./DinnerTable";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
    },
    seatingContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
  })
);

export interface AgendaDinnerDetailProps {
  description: string;
  dinnerSeatings: DinnerSeating[];
  focusedRegistrationID: string;
  focusedBusinessPartnerID: string;
  focusedTableID: number;
  focusedParticipation: boolean;
}

export const AgendaDinnerRoundDetail: React.FC<AgendaDinnerDetailProps> = ({
  description,
  dinnerSeatings,
  focusedRegistrationID,
  focusedBusinessPartnerID,
  focusedTableID,
  focusedParticipation,
}) => {
  const classes = useStyles();

  return (

    <Box className={classes.container}>
      <Typography
        color="textSecondary"
        align="left"
        style={{ marginBottom: 20 }}
      >
        {focusedParticipation ?
          description
        : "You are not attending this round"}
      </Typography>
      <Box className={classes.seatingContainer}>
      {focusedParticipation?
        <DinnerTable
          dinnerSeatings={dinnerSeatings}
          focusedRegistrationID={focusedRegistrationID}
          focusedBusinessPartnerID={focusedBusinessPartnerID}
          focusedTableID={focusedTableID}
          tableLength={3}
          tableWidth={2}
        />
        :
        ""
      }
      </Box>
    </Box>
  );
};
