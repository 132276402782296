import React, {useState} from "react";
import Config from "../../config";
import {
    Box,
    createStyles,
    IconButton,
    Typography,
    makeStyles,
    Theme,
    TextField
} from "@material-ui/core";
import {ThinkTankContent} from "../../models/models";
import {ThinkTankKeywordsScreen} from "./ThinkTankKeywords";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import parse from 'html-react-parser';
import {v4 as uuid} from "uuid";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      flex: 1,
      display: "flex",
      alignItems: "top",
      justifyContent: "flexStart",
      backgroundColor: 'transparent',
      marginTop: 10,

    },
    thinkTankContent: {
      display: "flex",
      flexDirection: "column",
      borderRadius: 10,
      width: "100%",
      paddingBottom: 10,
      paddingLeft: 10,
      paddingRight: 10,
      marginTop: 10,
      marginBottom: 10,
      backgroundColor: theme.palette.background.paper,


    },
  })
);

export const ThinkTankContentItem: React.FC<{
  thinkTankContent: ThinkTankContent,
  updateParentThinkTank: (thinkTankContent: ThinkTankContent) => void;
}> = ( {
  thinkTankContent,
  updateParentThinkTank,
}) => {

  const classes = useStyles();

  const addKeywordToContent = (thinkTankKeyword: ThinkTankKeyword) => {
    let thinkTankContentTemp = {...thinkTankContent};
    thinkTankContentTemp.keywords.push(thinkTankKeyword);
    thinkTankContentTemp.status = 2; //edited content sicne keyword has been added
    updateParentThinkTank(thinkTankContentTemp);
  }

  const [mutableThinkTankContent, setMutableThinkTankContent] = useState<ThinkTankContent>(thinkTankContent);


  return (
       <Box className={classes.thinkTankContent}
             key={thinkTankContent.id}
       >

         {mutableThinkTankContent.contentEn &&
           <Box className={classes.row}>
               <Typography color="textSecondary">
                 {parse(mutableThinkTankContent.contentEn)}
               </Typography>
           </Box>
         }

        <ThinkTankKeywordsScreen
          parentThinkTank={thinkTankContent}
          updateParentThinkTank={addKeywordToContent}
        />

      </Box>
  );
};
