import React, {useState} from "react";

import {authenticate, selectError, selectIsFetching, selectAuthenticationMethod} from "./authenticationSlice";
import {
    Box,
    Button,
    createStyles,
    IconButton,
    InputAdornment,
    LinearProgress,
    makeStyles,
    TextField,
    Theme,
    Typography,
} from "@material-ui/core";
import VisibilityRoundedIcon from "@material-ui/icons/VisibilityRounded";
import VisibilityOffRoundedIcon from "@material-ui/icons/VisibilityOffRounded";
import {FormattedMessage} from "react-intl";
import Logo from "../../resources/logo.svg";
import {useDispatch, useSelector} from "react-redux";
import Config from "../../config";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      zIndex: 20000,
      flex: 1,
      display: "flex",
      flexDirection: "column",
      overflow: "auto",
      paddingBottom: 20,
      position: "fixed",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      padding: 20,
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: theme.palette.background.default,
    },
    card: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: 10,
      overflow: "hidden",
    },
    cardContainer: {
      display: "flex",
      flexDirection: "column",
      padding: 20,
    },
    textField: {
      marginBottom: 20,
      backgroundColor: theme.palette.background.default,
    },
    logo: {
      height: 20,
      marginBottom: 40,
    },
    logoContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    text: {
      marginBottom: 20,
    },
    error: {
      backgroundColor: theme.palette.secondary.main,
      padding: 10,
      borderRadius: 10,
      marginBottom: 20,
    },
  })
);


export function Authentication() {
  const dispatch = useDispatch();


  const classes = useStyles();

  const [password, setPassword] = useState<string>("");

  const [showPassword, setShowPassword] = useState<boolean>(false);

    /**
   *
   * @param event
   */
  const onLoginButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (password) {
      const businessPartnerID = Config.getInstance().getBusinessPartnerID();

      // if a business partner ID is present we can assume that a representative is trying to log in
      if (businessPartnerID) {
        // business partner login
        dispatch(
          authenticate({
            eventID: Config.getInstance().getEventID(),
            businessPartnerID: Config.getInstance().getBusinessPartnerID(),
            password: password,
            authenticationMethod: 'password',
          })
        );
      } else {
        // participant login
        dispatch(
          authenticate({
            username: Config.getInstance().getRegistrationID(),
            password: password,
            authenticationMethod: 'password',
          })
        );
      }
    }
  };

  const isFetching = useSelector(selectIsFetching);
  const error = useSelector(selectError);
  const authMethod = useSelector(selectAuthenticationMethod);

  if (authMethod === 'token' && !error) {
    return (
    <Box className={classes.container}>
      <Box className={classes.card}>
        {isFetching && <LinearProgress color="primary" />}
        <Box className={classes.cardContainer}>
          <Typography
            color="textSecondary"
            align="center"
            className={classes.text}
          >
            Redirecting
          </Typography>
        </Box>
      </Box>
    </Box>
  )
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.card}>
        {isFetching && <LinearProgress color="primary" />}
        <Box className={classes.cardContainer}>
          <Box className={classes.logoContainer}>
            <img alt="logo" className={classes.logo} src={Logo} />
          </Box>
          <Typography
            color="textSecondary"
            align="center"
            className={classes.text}
          >
            Please provide the password you should have received via email
          </Typography>
          {error && (
            <Box className={classes.error}>
              <Typography color="textPrimary">{error}</Typography>
            </Box>
          )}
          <TextField
            size="small"
            label="Password"
            variant="filled"
            type={showPassword ? "text" : "password"}
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                  <InputAdornment position="end">
                      <IconButton size="small" onClick={() => setShowPassword(!showPassword)}>
                          {showPassword &&
                              <VisibilityRoundedIcon/>
                          }
                          {!showPassword &&
                              <VisibilityOffRoundedIcon/>
                          }
                      </IconButton>
                  </InputAdornment>
              ),
            }}
            className={classes.textField}
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
          <Button
            color="primary"
            size="small"
            variant="contained"
            onClick={onLoginButtonClick}
          >
            <FormattedMessage id="login" defaultMessage="Login" />
          </Button>
        </Box>
      </Box>
    </Box>
  );
}



export function AuthenticationQRCode() {
  const dispatch = useDispatch();

  const classes = useStyles();

  const businessPartnerID = Config.getInstance().getBusinessPartnerID();
  // if a business partner ID is present we can assume that a representative is trying to log in

//  const onRedirectButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
//      const businessPartnerID = Config.getInstance().getBusinessPartnerID();

      // if a business partner ID is present we can assume that a representative is trying to log in
      if (businessPartnerID) {
        // business partner login
        dispatch(
          authenticate({
            eventID: Config.getInstance().getEventID(),
            businessPartnerID: Config.getInstance().getBusinessPartnerID(),
            accessTokenQRCode: Config.getInstance().getAccessTokenQRCode(),
            authenticationMethod: 'token',
          })
        );
      } else {
        // participant login
        dispatch(
          authenticate({
            username: Config.getInstance().getRegistrationID(),
            accessTokenQRCode: Config.getInstance().getAccessTokenQRCode(),
            authenticationMethod: 'token',
          })
        );
      };
//  };

  const isFetching = useSelector(selectIsFetching);
  const error = useSelector(selectError);

  return (
    <Box className={classes.container}>
      <Box className={classes.card}>
        {isFetching && <LinearProgress color="primary" />}
        <Box className={classes.cardContainer}>
          <Typography
            color="textSecondary"
            align="center"
            className={classes.text}
          >
            Redirecting
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
