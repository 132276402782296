import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk, RootState} from "../../app/store";
import Config from "../../config";

interface EventState {
  isFetching: boolean;
  token: null | string;
  registrationID: null | string;
  registrationType: null | string;
  error: null | string;
  authenticationMethod: null | string;
  enableRegistrationsMenu: null | boolean;
}

const initialState: EventState = {
  isFetching: false,
  token: null,
  registrationID: null,
  registrationType: null,
  error: null,
  authenticationMethod: null,
  enableRegistrationsMenu: false,
};

export const authenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    storeFetching: (state, action: PayloadAction<boolean>) => {
      state.isFetching = action.payload;
    },
    storeToken: (state, action: PayloadAction<string | null>) => {
      state.token = action.payload;
    },
    storeRegistrationID: (state, action: PayloadAction<string | null>) => {
      state.registrationID = action.payload;
    },
    storeRegistrationType: (state, action: PayloadAction<string | null>) => {
      state.registrationType = action.payload;
    },
    storeError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
    storeAuthenticationMethod: (state, action: PayloadAction<string | null>) => {
      state.authenticationMethod = action.payload;
    },
    storeEnableRegistrationsMenu: (state, action: PayloadAction<boolean | false>) => {
      state.enableRegistrationsMenu = action.payload;
    },
    purgeStore: (state) => {
      state.isFetching = false;
      state.token = null;
      state.registrationID = null;
      state.registrationType = null;
      state.error = null;
      state.authenticationMethod = null;
      state.enableRegistrationsMenu = false;
    },
  },
});

export const {
  storeFetching,
  storeToken,
  storeRegistrationID,
  storeRegistrationType,
  storeError,
  storeAuthenticationMethod,
  storeEnableRegistrationsMenu,
  purgeStore,
} = authenticationSlice.actions;

/**
 * Retrieve an authentication token from the application server and store it into the authentication slice
 * @param username The username to authenticate with
 * @param password The password corresponding to given user
 */
export const authenticate =
  ({
    username,
    password,
    accessTokenQRCode,
    eventID,
    businessPartnerID,
    registrationType,
    authenticationMethod,
    enableRegistrationsMenu,
  }: {
    username?: string;
    password?: string;
    accessTokenQRCode?: string;
    eventID?: string;
    businessPartnerID?: string;
    registrationType?: string;
    authenticationMethod?: string;
    enableRegistrationsMenu?: boolean;
  }): AppThunk =>
  async (dispatch) => {
    try {
      const headers: Headers = new Headers();

      headers.append("Accept", "application/json");
      headers.append("Content-Type", "application/json");

      dispatch(storeAuthenticationMethod(authenticationMethod || null));

      let url = `${Config.getInstance().getCoordinationServiceURL()}/api/login/`;

      dispatch(storeFetching(true));

      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          username: username,
          password: password,
          access_token_qrcode: accessTokenQRCode,
          event_id: eventID,
          business_partner_id: businessPartnerID,
          registration_type: registrationType,
          enable_registrations_menu: enableRegistrationsMenu,
        }),
      });


      if (!response.ok) {
        dispatch(storeError("Access token expired or incorrect username / password"));
      } else {
        dispatch(storeError(null));
      }

      const data = await response.json();

      dispatch(storeRegistrationID(data["registration_id"]));
      dispatch(storeToken(data["access_token"]));
      dispatch(storeRegistrationType(data["registration_type"]));
      dispatch(storeEnableRegistrationsMenu(data["enable_registrations_menu"]));

      dispatch(storeFetching(false));
    } catch (error) {
      dispatch(storeFetching(false));
      console.error(error);
    }
  };

/**
 * Retrieve whether the authentication slice is currently syncing with the application server
 * @param state The redux root state
 */
export const selectIsFetching = (state: RootState) =>
  state.authenticationStore.isFetching;

/**
 * Retrieve the current authentication token from the authentication slice. The token is either a string
 * if the application is authenticated or null if it is not.
 * @param state The redux root state
 */
export const selectToken = (state: RootState) =>
  state.authenticationStore.token;

/**
 * Retrieve the current registration ID from the authentication slice.
 * @param state The redux root state
 */
export const selectRegistrationID = (state: RootState) =>
  state.authenticationStore.registrationID;

/**
 * Retrieve the current authentication mehtod
 * @param state The redux root state
 */
export const selectAuthenticationMethod = (state: RootState) =>
  state.authenticationStore.authenticationMethod;

/**
 * Retrieve if regisatrations are to be displayed
 * @param state The redux root state
 */
export const selectEnableRegistrationsMenu = (state: RootState) =>
  state.authenticationStore.enableRegistrationsMenu;

/**
 * Retrieve the current registration type from the authentication slice.
 * @param state The redux root state
 */
export const selectRegistrationType = (state: RootState) =>
  state.authenticationStore.registrationType;

/**
 * Retrieve the current error from the authentication slice. The error is either a string
 * if an error is present or null.
 * @param state The redux root state
 */
export const selectError = (state: RootState) =>
  state.authenticationStore.error;

export default authenticationSlice.reducer;
