import React, {useState} from "react";
import {
    Slide,
    createStyles,
    Dialog,
    IconButton,
    makeStyles,
    Toolbar,
    Typography
} from "@material-ui/core";
import {CloseRounded} from "@material-ui/icons";
import {ThinkTankTable, ThinkTankPriority} from "../../models/models";
import {ThinkTankContentsScreen} from "./ThinkTankContents";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      backgroundColor: theme.palette.background.default,
    },
    toolBar: {
      border: `1px solid ${theme.palette.background.paper}`,
    },
  })
);


//Dialog screen for a certain think tank table
export const ThinkTankTableScreen: React.FC<{
  thinkTankTable: ThinkTankTable,
  thinkTankPriorities: ThinkTankPriority[],
  closeDialog: (thinkTankTable: ThinkTankTable, priorityOrder: number[], priorityOrderHasChanged: boolean) => void,  //send it back to parent on clos
}> = ({
  thinkTankTable,
  thinkTankPriorities,
  closeDialog,
}) => {

  const classes = useStyles();
  const [thisThinkTankTable, setThisThinkTankTable] = useState<ThinkTankTable>(thinkTankTable);
  const [thisPriorityOrderHasChanged, setThisPriorityOrderHasChanged] = useState<Boolean>(false);
  const [thisPriorityOrder, setThisPriorityOrder] = useState<number[]>(
     thinkTankTable.contents.map((_, index) => index).sort((firstElement, secondElement) => {
              //database id of the first and second content element
              const firstId = thinkTankTable.contents[firstElement].dbId;
              const secondId = thinkTankTable.contents[secondElement].dbId;

              //priorities of the first and second element (by default, very low priority, i.e., high value)
              const firstPriority = thinkTankPriorities.find((thisPriority) => thisPriority.thinkTankDbId === firstId)?.priority;
              const secondPriority = thinkTankPriorities.find((thisPriority) => thisPriority.thinkTankDbId === secondId)?.priority;

              if(firstPriority === undefined){
                return 1
              }
              else if(secondPriority === undefined){
                return -1
              }
              else if(firstPriority < secondPriority){
                    return -1
                }
              else if(firstPriority > secondPriority){
                    return 1
                }
              return 0
              }
            )
          );

  const updateContentsInTable = (thinkTankContents: ThinkTankContents[]) => {
    let thinkTankTableTemp = {...thisThinkTankTable};
    thinkTankTableTemp.contents=thinkTankContents;
    setThisThinkTankTable(thinkTankTableTemp);
  }

  const updatePriorityOrder = (order: number[]) => {
    setThisPriorityOrder(order);
    setThisPriorityOrderHasChanged(true);
  }

  const closeDialogLocal = () => {
    closeDialog(thisThinkTankTable, thisPriorityOrder, thisPriorityOrderHasChanged);
  }


  return (
      <Dialog
          fullScreen
          open={Boolean(thinkTankTable)}
          onClose={closeDialogLocal}
          TransitionComponent={Slide}
          TransitionProps={{direction: "up"} as any}
          PaperProps={{
            className: classes.dialog
          }}
      >
    <Toolbar className={classes.toolBar} style={{padding: 10}}>
      <Typography style={{ marginLeft: 2, flex: 1 }} variant="h6" component="div">
          {thinkTankTable.title}
      </Typography>
      <IconButton
          edge="start"
          color="inherit"
          onClick={closeDialogLocal}
          aria-label="close"
      >
        <CloseRounded />
      </IconButton>
    </Toolbar>

    <ThinkTankContentsScreen
       parentThinkTank = {thisThinkTankTable}
       priorityOrder = {thisPriorityOrder}
       updateParentThinkTank = {updateContentsInTable}
       updatePriorityOrder = {updatePriorityOrder}
    />
    </Dialog>
  );
};
