import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../app/store";
import {Notification} from "../../models/models";

interface EventState {
  notifications: Notification[];
}

const initialState: EventState = {
  notifications: [],
};

export const notificationSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    enqueueNotification: (state, action: PayloadAction<Notification>) => {
      state.notifications = [
        ...state.notifications,
        {
          ...action.payload,
          id: action.payload.id || new Date().getTime() + Math.random(),
        },
      ];
    },
    closeNotification: (state, action: PayloadAction<number>) => {
      state.notifications = state.notifications.map((notification) => ({
        ...notification,
        dismissed:
          notification.id === action.payload ? true : notification.dismissed,
      }));
    },
    removeNotification: (state, action: PayloadAction<number>) => {
      state.notifications = state.notifications.filter(
        (notification) => notification.id !== action.payload
      );
    },
    purgeStore: (state) => {
      state.notifications = [];
    },
  },
});

export const {
  enqueueNotification,
  closeNotification,
  removeNotification,
  purgeStore,
} = notificationSlice.actions;

export const selectNotifications = (state: RootState) =>
  state.notificationStore.notifications;

export default notificationSlice.reducer;
