import React, {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import Config from "../../config";
import {
    Box,
    createStyles,
    makeStyles,
    Typography,
    Theme
} from "@material-ui/core";
import {animated, config, to, useSprings} from "@react-spring/web";
import {useDrag} from "react-use-gesture";
import {ThinkTankTable, ThinkTankPriority, ThinkTankContent} from "../../models/models";
import {ThinkTankContentItem} from "./ThinkTankContentItem";
import {selectProfile} from "../registrations/registrationsSlice";
import {selectEvent} from "../badge/eventSlice";

const dragBoxHeight = 180;  //height of the dragable box
const dragBoxSwapThreshold = 90 //pixels to move the draggable box until swapped

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: 20,
    },
    headerContainer:{
      display: "flex",
      flexDirection: "column",
    },
  })
);

interface ThinkTankContentsScreenProps {
  parentThinkTank: ThinkTankTable;
  priorityOrder: number[];
  updateParentThinkTank: (thinkTankContents: ThinkTankContent[]) =>  void;
  updatePriorityOrder: (order: number[]) => void;
}

export const ThinkTankContentsScreen: React.FC<ThinkTankContentsScreenProps> = ( {
  parentThinkTank,
  priorityOrder,
  updateParentThinkTank,
  updatePriorityOrder,
}) => {
//screen to show all contents

  const profile = useSelector(selectProfile(Config.getInstance().getRegistrationID()));
  const event = useSelector(selectEvent);

//when content has been updated, push to parent
  const updateContentInContents = (thinkTankContent: ThinkTankContent) => {
    let thinkTankContentsTemp = [...parentThinkTank.contents];
    let indxContent = thinkTankContentsTemp.map(thinkTankContent => thinkTankContent.id).indexOf(thinkTankContent.id);
    thinkTankContentsTemp[indxContent] = thinkTankContent;
    updateParentThinkTank(thinkTankContentsTemp);
  }

  const classes = useStyles();

  const today = new Date();
  const eventDate = new Date(event.eventDate);
  eventDate.setHours(23,59,59); //set time to the end of the day
  const diffDays = (eventDate-today)/1000/3600/24

//draggable component
  return (
        <Box className={classes.container}>
           {parentThinkTank.contents.map((thisContent) => {
                return (
                     <ThinkTankContentItem
                        thinkTankContent={thisContent}
                        updateParentThinkTank={updateContentInContents}
                      />
                )
              })
           }
        </Box>
  );
};
