const DEFAULT_HOST = "http://localhost:5001";
//const DEFAULT_HOST = "http://192.168.178.97:5001";
const DEFAULT_WEBSOCKET_URL = "http://192.168.178.97:5001/websocket_dynamic_think_tanks";

const DEFAULT_SALESFORCE_HOST =
  "https://networkcircle--partialnc.lightning.force.com/";

// const DEFAULT_EVENT_ID = "a0M3Y00001FcEu7UAF";
// const DEFAULT_REGISTRATION_ID = "a0L3Y00000nO4qHUAS";

export default class Config {
  private static instance: Config;

  /**
   * Retrieve the shared config object
   * @return Config the shared config object
   */
  public static getInstance(): Config {
    if (!Config.instance) {
      Config.instance = new Config();
    }

    return Config.instance;
  }

  /**
   *  Retrieve the url of the salesforce server
   *  @return string the url of the salesforce server
   */
  getSalesforceServerURL(): string {
    return DEFAULT_SALESFORCE_HOST;
  }

  /**
   *  Retrieve the url of the coordination service
   *  @return string the url of the coordination service
   */
  getCoordinationServiceURL(): string {
    return process.env.REACT_APP_COORDINATION_SERVICE_URL || DEFAULT_HOST;
  }

   /**
   *  Retrieve the url of the websocket
   *  @return string the url of the websocket
   */
  getWebsocketURL(): string {
    return process.env.REACT_APP_WEBSOCKET_URL || DEFAULT_WEBSOCKET_URL;
  }

  /**
   * Retrieve the current event ID
   * @return string the current event ID
   */
  getEventID(): string {
    const urlSearchParams = new URLSearchParams(window.location.search);
    return urlSearchParams.get("event_id") || "";
  }

  /**
   * Retrieve the current registration ID
   * @return string the current registration ID
   */
  getRegistrationID(): string {
    const urlSearchParams = new URLSearchParams(window.location.search);
    return urlSearchParams.get("registration_id") || "";
  }

  /**
   * Retrieve the current registration ID
   * @return string the current registration ID
   */
  getBusinessPartnerID(): string {
    const urlSearchParams = new URLSearchParams(window.location.search);
    return urlSearchParams.get("business_partner_id") || "";
  }

  /**
   * Retrieve the current access token defined in url/qrcode
   * @return string the current access token
   */
  getAccessTokenQRCode(): string {
    const urlSearchParams = new URLSearchParams(window.location.search);
    return urlSearchParams.get("access_token") || "";
  }

}
