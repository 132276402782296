import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk, RootState} from "../../app/store";
import {ThinkTankPriority,
        ThinkTankPriorityJSON,
        ThinkTankPriorityFactory,} from "../../models/models";
import Config from "../../config";

interface ThinkTankPrioritiesState {
  isFetching: boolean;
  thinkTankPriorities: ThinkTankPriorityJSON[];
}

const initialState: ThinkTankPrioritiesState = {
  isFetching: true,
  thinkTankPriorities: [],
};

export const thinkTankPrioritiesSlice = createSlice({
  name: "thinkTankPriorities",
  initialState,
  reducers: {
    storeFetching: (state, action: PayloadAction<boolean>) => {
      state.isFetching = action.payload;
    },
    storeThinkTankPriorities: (state, action: PayloadAction<ThinkTankPriority[]>) => {
      const thinkTankPriorityFactory = new ThinkTankPriorityFactory();

      state.thinkTankPriorities = action.payload.map((thinkTankPriority) =>
        thinkTankPriorityFactory.toJSON(thinkTankPriority)
      );
    },
    purgeStore: (state) => {
      state.isFetching = false;
      state.thinkTanksPriorities = initialState.thinkTankPriorities;
    },
  },
});

export const { storeFetching, storeThinkTankPriorities, purgeStore } = thinkTankPrioritiesSlice.actions;

export const retrieveThinkTankPriorities =
  (authToken: string, eventID: string, registrationID: string): AppThunk =>
  async (dispatch) => {

    try {
      const headers: Headers = new Headers();

      headers.append("Accept", "application/json");
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", `Bearer ${authToken}`);

      dispatch(storeFetching(true));

      let url = `${Config.getInstance().getCoordinationServiceURL()}/api/events/${eventID}/${registrationID}/think_tank_priorities/`;

      fetch(url, {
        method: "GET",
        headers: headers,
      })
        .then((response) => response.json())
        .then((data) => {
          // make sure the returned data is actually array-shaped
          if (!data || !Array.isArray(data)) {
            return;
          }

          dispatch(purgeStore());

          dispatch(
            storeThinkTankPriorities(
              data.map((thinkTankPriorityJSON) => {
                return new ThinkTankPriorityFactory().fromJSON(thinkTankPriorityJSON)
              }
              )
            )
          );
         dispatch(storeFetching(false));
        })
        .catch((error) => {
          dispatch(storeFetching(false));
          console.error(error);
        });
    } catch (error) {
      dispatch(storeFetching(false));
      console.error(error);
    }
  };

export const selectThinkTankPriorities = (state: RootState) =>
  state.thinkTankPrioritiesStore.thinkTankPriorities.map((thinkTankPriorityJSON) =>
     new ThinkTankPriorityFactory().fromJSON(thinkTankPriorityJSON)
  );

export const selectThinkTankPrioritiesIsFetching = (state: RootState) =>
  state.thinkTankPrioritiesStore.isFetching;

export default thinkTankPrioritiesSlice.reducer;
