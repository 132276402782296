import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import {persistor, store} from "./app/store";
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/es/integration/react";
import {SnackbarProvider} from "notistack";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{
          variantSuccess: "snackbar-success",
        }}
      >
        <PersistGate persistor={persistor}>
          <App />
        </PersistGate>
      </SnackbarProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
