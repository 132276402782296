import React from "react";
import {Box, Button, createStyles, IconButton, makeStyles, Theme, Typography,} from "@material-ui/core";
import {Registration, ThinkTankContent} from "../../models/models";
import LinkedIn from "../../resources/linkedin.svg";
import {openLinkedInProfile} from "../../utils/linkedin";
import {useHistory} from "react-router";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            flex: 1,
            display: "flex",
            flexDirection: "column",
        },
        row: {
            display: "flex",
            alignItems: "center",
            paddingLeft: 10,
            paddingRight: 10,
        },
        indicator: {
            height: 10,
            width: 10,
            borderRadius: 5,
            backgroundColor: theme.palette.primary.main,
            marginRight: 10,
        },
        name: {
            fontWeight: "bold",
        },
        linkedIn: {
            height: 20,
            width: 20,
        },
        thinkTankContent: {
            padding: 10,
            borderRadius: 10,
            backgroundColor: theme.palette.background.paper,
            marginBottom: 20
        }
    })
);

export interface AgendaThinkTankRoundDetailProps {
    name: string;
    title: string;
    tableNumber: number;
    description: string;
    participants: Registration[];
    contents: ThinkTankContent [];
    registrationType: string;
}

export const AgendaThinkTankRoundDetail: React.FC<AgendaThinkTankRoundDetailProps> = ({
                                                                                          name,
                                                                                          title,
                                                                                          tableNumber,
                                                                                          description,
                                                                                          participants,
                                                                                          contents,
                                                                                          registrationType
                                                                                      }) => {
    const classes = useStyles();

    const history = useHistory();

    const openInNewTab = (url: string): void => {
      const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
      if (newWindow) newWindow.opener = null
    }

    return (
        <Box className={classes.container}>
            <Typography
                color="textSecondary"
                align="left"
                style={{marginBottom: 40}}
            >
          {title === "Not seated" ? "Not attending in this round" : description}
            </Typography>
            {title !== "Not seated" &&
            <Typography
                color="textPrimary"
                align="left"
                style={{fontWeight: "bold", marginBottom: 10}}
            >
                Table Nr. {tableNumber} -- {title}
            </Typography>
          }

          {title !== "Not seated" && registrationType !== "Executive Registration" &&
            <Typography
                color="textPrimary"
                align="left"
                style={{fontWeight: "bold", marginBottom: 10}}
            >
                Other Participants
            </Typography>
          }
          {title !== "Not seated" && registrationType !== "Executive Registration" &&
            participants?.map((participant: Registration, index: number) => (
                <Button
                    className={classes.row}
                    style={{marginTop: index > 0 ? 10 : 0}}
                    onClick={() =>
                        history.push({
                            pathname: `/app/registrations/${participant.id}`,
                            search: window.location.search,
                        })
                    }
                >
                    <Box>
                        <Typography
                            align="left"
                            color="textPrimary"
                            className={classes.name}
                        >
                            {participant.name}
                        </Typography>
                        <Typography align="left" color="textSecondary">
                            {participant.title}, {participant.company}
                        </Typography>
                    </Box>
                    <Box style={{flex: 1}}/>
                    <IconButton
                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                            event.stopPropagation();
//                            openLinkedInProfile(participant.name, participant.company);
                            openInNewTab(participant.linkedinUrl);
                          }}
                        disabled={participant.linkedinUrl === ""}
                  >
                   {(participant.linkedinUrl === "") ? "" :
                        <img alt="logo" className={classes.linkedIn} src={LinkedIn}/>
                    }
                    </IconButton>
                </Button>
            ))}
        </Box>
    );
};
