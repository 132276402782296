import React, {useEffect} from "react";
import {Avatar, Badge, Box, createStyles, IconButton, makeStyles, Theme, Typography,} from "@material-ui/core";
import MailIconRounded from "@material-ui/icons/MailRounded";
import PhoneIconRounded from "@material-ui/icons/PhoneRounded";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router";
import {openLinkedInProfile} from "../../utils/linkedin";
import LinkedIn from "../../resources/linkedin.svg";
import {getInitials} from "../../utils";
import Config from "../../config";
import {selectToken} from "../authentication/authenticationSlice";

import {retrieveProfile, selectProfile} from "../registrations/registrationsSlice";
import {retrieveRegistrations, selectRegistration, selectRegistrations,} from "../registrations/registrationsSlice";
import {retrieveEvent, selectEvent} from "../badge/eventSlice";
import {FirstLoginFlowDialog} from "../firstLoginFlow/FirstLoginFlowDialog";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      backgroundColor: theme.palette.background.default,
      overflow: "auto",
      paddingBottom: 20,
    },
    focusArea: {
      backgroundColor: theme.palette.primary.main,
      height: 200,
      padding: 20,
    },
    row: {
      display: "flex",
      alignItems: "center",
      marginBottom: 20,
    },
    indicator: {
      height: 10,
      width: 10,
      borderRadius: 5,
      backgroundColor: theme.palette.primary.main,
      marginRight: 10,
    },
    name: {
      fontWeight: "bold",
    },
    linkedIn: {
      height: 20,
      width: 20,
    },
    badge: {
      "& .MuiBadge-badge": {
        boxShadow: `0 0 0 2px ${theme.palette.background.default}`,
        height: 16,
        width: 16,
        borderRadius: 8,
      },
    },
    avatar: {
      height: 100,
      width: 100,
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.primary.contrastText,
    },
  })
);

export const Registration = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  let params = useParams();

  const registration = useSelector(selectRegistration((params as any).registrationID));

  const profile = useSelector(selectProfile((params as any).registrationID));

  const authToken = useSelector(selectToken);

  useEffect(() => {
    if (authToken) {
      dispatch(retrieveProfile(authToken,Config.getInstance().getEventID(),(params as any).registrationID));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps



  const openInNewTab = (url: string): void => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }


  return (
    <Box className={classes.container}>

      <Box style={{ padding: 20 }}>
        <Box style={{ height: 40 }} />
        <Badge
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          className={classes.badge}
          variant="dot"
          color={
            registration?.participationStatus === "Cancellation - other reason"
              ? "secondary"
              : "primary"
          }
          overlap="circle"
        >
          <Avatar alt="Remy Sharp" className={classes.avatar}>
            {getInitials(registration?.name || "")}
          </Avatar>
        </Badge>
        <Box style={{ height: 40 }} />
        <Typography
          variant="h6"
          color="textPrimary"
          style={{ fontWeight: "bold" }}
        >
          {registration?.name}
        </Typography>
        <Typography color="textSecondary" style={{ fontWeight: "bold" }}>
          {registration?.title}
        </Typography>
        <Typography color="textSecondary">{registration?.company}</Typography>
        <Typography
          align="center"
          color="textSecondary"
          style={{ marginTop: 20 }}
        >
          {profile?.address}
        </Typography>
        <Box style={{ height: 40 }} />
        <Box className={classes.row}>
          <Box>
            <Typography
              color="textPrimary"
              align="left"
              style={{ fontWeight: "bold", marginTop: 20 }}
            >
              Phone
            </Typography>
            <Typography align="left" color="textSecondary">
              {profile?.acceptLegalNotice ? profile.phoneNumber : "-"}
            </Typography>
          </Box>
          <Box style={{ flex: 1 }} />
          <IconButton
            disabled={!profile?.acceptLegalNotice}
            onClick={() => window.open(`tel:${profile?.phoneNumber}`)}
          >
            <PhoneIconRounded />
          </IconButton>
        </Box>
        <Box className={classes.row}>
          <Box>
            <Typography
              color="textPrimary"
              align="left"
              style={{ fontWeight: "bold", marginTop: 10 }}
            >
              Email
            </Typography>
            <Typography align="left" color="textSecondary">
              {profile?.acceptLegalNotice ? profile.email : "-"}
            </Typography>
          </Box>
          <Box style={{ flex: 1 }} />
          <IconButton
            disabled={!profile?.acceptLegalNotice}
            onClick={() => window.open(`mailto:${profile?.email}`)}
          >
            <MailIconRounded />
          </IconButton>
        </Box>
        <Box className={classes.row}>
          <Box>
            <Typography
              color="textPrimary"
              align="left"
              style={{ fontWeight: "bold", marginTop: 10 }}
            >
             {(profile?.linkedinUrl === "") ? "" : "LinkedIn"}
            </Typography>
            <Typography align="left" color="textSecondary">
              {(profile?.linkedinUrl === "") ? "" : registration?.name}
            </Typography>
          </Box>

          <Box style={{ flex: 1 }} />
          <IconButton
            onClick={() => openInNewTab(profile.linkedinUrl)}
            disabled={profile.linkedinUrl === ""}
          >
            {(profile?.linkedinUrl === "") ? "" :
            <img alt="logo" className={classes.linkedIn} src={LinkedIn}/>
          }
          </IconButton>
        </Box>

        <Box className={classes.row}>
          <Box>
            <Typography
              color="textPrimary"
              align="left"
              style={{ fontWeight: "bold", marginTop: 10 }}
            >
             {(profile?.description === "") ? "" : "Additional Information"}
            </Typography>
            <Typography align="left" color="textSecondary">
              {(profile?.description === "") ? "" : profile.description}
            </Typography>
          </Box>
          </Box>


      </Box>


    </Box>
  );
};
