export const deepEquals = (first: Object, second: Object) => {
  return JSON.stringify(first) === JSON.stringify(second);
};

export const getInitials = (name: string) => {
  return name
    .replace("Dr. ", "")
    .split(" ")
    .map((word) => word[0])
    .join("");
};
