import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk, RootState} from "../../app/store";
import {Event, EventFactory, EventJSON} from "../../models/models";
import Config from "../../config";

interface EventState {
  isFetching: boolean;
  event: EventJSON | undefined;
}

const initialState: EventState = {
  isFetching: false,
  event: undefined,
};

export const eventSlice = createSlice({
  name: "event",
  initialState,
  reducers: {
    storeFetching: (state, action: PayloadAction<boolean>) => {
      state.isFetching = action.payload;
    },
    storeEvent: (state, action: PayloadAction<Event>) => {
      state.event = new EventFactory().toJSON(action.payload);
    },
    purgeStore: (state) => {
      state.event = undefined;
    },
  },
});

export const { storeEvent, purgeStore } = eventSlice.actions;

export const retrieveEvent =
  (authToken: string, eventID: string): AppThunk =>
  async (dispatch) => {
    try {
      const headers: Headers = new Headers();

      headers.append("Accept", "application/json");
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", `Bearer ${authToken}`);

      let url = `${Config.getInstance().getCoordinationServiceURL()}/api/events/${eventID}/`;

      fetch(url, {
        method: "GET",
        headers: headers,
      })
        .then((response) => response.json())
        .then((data) => {
          dispatch(purgeStore());

          dispatch(storeEvent(new EventFactory().fromJSON(data)));
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error(error);
    }
  };

export const selectEvent = (state: RootState) =>
  state.eventStore.event
    ? new EventFactory().fromJSON(state.eventStore.event)
    : new EventFactory().init();

export default eventSlice.reducer;
