import React, {useEffect, useRef, useState} from "react";
import {
    Box,
    Button,
    createStyles,
    Dialog,
    IconButton,
    makeStyles,
    Slide,
    Theme,
    Toolbar,
    Typography,
    TextField,
    Stepper,
    Step,
    StepLabel,
    Divider,
    Switch,
    withStyles,
} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import Config from "../../config";
import {retrieveThinkTanks, selectThinkTanks} from "../thinkTanks/thinkTankSlice";
import {CloseRounded, DragIndicatorRounded, InfoRounded} from "@material-ui/icons";
import {animated, config, to, useSprings} from "@react-spring/web";
import {useDrag} from "react-use-gesture";
import {Registration, RegistrationJSON, RegistrationFactory, ThinkTankTable,} from "../../models/models";
import {retrieveRegistrations, selectRegistration, updateRegistration,} from "../registrations/registrationsSlice";
import {selectToken} from "../authentication/authenticationSlice";
import {useSnackbar} from "notistack";
//import ReactHtmlParser from 'react-html-parser';
import parse from 'html-react-parser';
import ReplayRoundedIcon from "@material-ui/icons/ReplayRounded";
import {retrieveProfile, selectProfile, updateProfile, updateContact} from "../registrations/registrationsSlice";
import {Profile, ProfileFactory, ProfileJSON} from "../../models/models";
import {Event} from "../../models/models";
import {daysUntilEvent} from "../../utils/dateFunctions";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
    container: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      paddingBottom: 20,
      paddingLeft: 20,
      paddingRight: 20,
      backgroundColor: theme.palette.background.default,
      overflow: "auto",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    })
);


export const ProfileSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 40,
      height: 24,
      padding: 0,
      margin: "10px 0px 10px 10px",
      display: "flex",
      overflow: "hidden",
      boxSizing: "border-box",
    },
    switchBase: {
      padding: 4,
      color: theme.palette.text.primary,
      "&$checked": {
        transform: "translateX(16px)",
        color: theme.palette.primary.main,
        "& + $track": {
          opacity: 1,
          backgroundColor: theme.palette.background.default,
          border: `2px solid ${theme.palette.primary.main}`,
        },
      },
    },
    thumb: {
      width: 16,
      height: 16,
      boxShadow: "none !important",
    },
    track: {
      borderRadius: 24 / 2,
      opacity: 1,
      backgroundColor: theme.palette.background.default,
      boxSizing: "border-box",
      border: `2px solid ${theme.palette.text.primary}`,
    },
    checked: {},
  })
)(Switch);



export const FirstLoginFlowDialog: React.FC<{
  authToken: string
  registrations: Registration[]
  profile: Profile
  event: Event
}> = ({
  authToken,
  registrations,
  profile,
  event,
}) => {

  const classes = useStyles();
  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const steps = ['Step 1: Restricted Information', 'Step 2: Personal Information'];
  const [activeStep, setActiveStep] = useState<number>(0);
  const [skippedSteps, setSkippedSteps] = useState<number[]>([]);
  const [completedSteps, setCompletedSteps] = useState<number[]>([]);
  const [showDietaryNeeds, setShowDietaryNeeds] = useState<boolean>(false);

  //handle next step
  const handleNext = () => {
    //allow next step if dietary needs are not set
    //allow next step if sharing is allowed
    if (!mutableProfile.mobile_phone_number){
      enqueueSnackbar("Please provide a mobile phone number", {
          variant: "error",
      });
    }
    else if(!mutableProfile.allow_dietary_needs_sharing) {
      enqueueSnackbar("Please accept the legal notice to proceed further.", {
          variant: "error",
      });
    }
    else {
         setActiveStep(
              (previousActiveStep) => {
                  let thisActiveStep = 0;
                  activeStep === steps.length - 1 ?
                  thisActiveStep = steps.length - 1 :
                  thisActiveStep = previousActiveStep + 1;
                  return thisActiveStep
                }
              );

    }
    };

  //handle previous step
  const handlePrevious = () => {
    setActiveStep(
        (previousActiveStep) =>
        {
          let thisActiveStep = 0;
          activeStep === 0 ?
              thisActiveStep = 0 :
              thisActiveStep = previousActiveStep - 1;
          return thisActiveStep
        }
      );
  };


//need initial state for mutableParticipant and mutableProfile
  const profileFactory = new ProfileFactory();
  const [mutableProfile, setMutableProfile] = useState<ProfileJSON>(profileFactory.toJSON(profileFactory.init()));

  const registrationFactory = new RegistrationFactory();

  const registration = registrations.filter(
                                   (registration) => registration.id === Config.getInstance().getRegistrationID())[0];

  const registrationIndex = registrations.indexOf(registration);

  const [mutableParticipant, setMutableParticipant] = useState<RegistrationJSON>(registrationFactory.toJSON(registrationFactory.init()));
  const [profileSaved, setProfileSaved] = useState<boolean>(false);

  useEffect(() => {
    if (profile) {
      setMutableProfile(profileFactory.toJSON(profile));
      if (profile?.dietaryNeeds == ""){
        setShowDietaryNeeds(false);
      }
      else {
        setShowDietaryNeeds(true);
      }
      if (event.name === "") {
        //don't open dialog if event name not defined
        setOpenDialog(false);
      }
      else if (!registration){
        setOpenDialog(false);
      }
      else if (registration.type === "Sales Registration") {
        setOpenDialog(false);
      }
      else if (!profile.acceptLegalNotice){
        //open the dialog if legal notice has not been accepted yet
        setOpenDialog(true);
      }

    }
  }, [JSON.stringify(profileFactory.toJSON(profile || profileFactory.init()))]); // eslint-disable-line react-hooks/exhaustive-deps

//  console.log(registrations['a0L3Y00000pgkiWUAQ']);
//  console.log(registrations.indexOf(registration))

//set mutable participant only if participant changes (e.g., if changed from undefined to the actual participant)
  useEffect(() => {
    if (registration) {
      setMutableParticipant(registrationFactory.toJSON(registration));
      if (event.name === "") {
        //don't open dialog if event name not defined
        setOpenDialog(false);
      }
      else if (!registration){
        setOpenDialog(false);
      }
      else if (registration.type === "Sales Registration") {
        setOpenDialog(false);
      }
      else if (!profile.acceptLegalNotice){
        //open the dialog if legal notice has not been accepted yet
        setOpenDialog(true);
      }
    }
  }, [JSON.stringify(registrationFactory.toJSON(registration || registrationFactory.init()))]);

  useEffect(() => {
    if(profileSaved === true){
      setOpenDialog(false);
    }
  }, [profileSaved])

  const saveProfileInformation = (
    authToken: string,
    eventID: string,
    registrationID: string,
    profile: Profile,
    registration: Registration,
  ) => {

    //check if agreed to terms and conditions
    if (profile.acceptLegalNotice) {
        let registrationsCopy = registrations;
        registrationsCopy[registrationIndex] = registration;

        dispatch(updateContact(authToken, eventID, registrationID, profile, registrationsCopy));

        setProfileSaved(true);

        enqueueSnackbar("Profile has been updated.", {
            variant: "success",
        });
    }
    else {
        enqueueSnackbar("Please accept the terms and conditions to proceed further.", {
            variant: "error",
        });
    }

  };

  //debug
  const [openDialog, setOpenDialog] = useState<boolean>(event.name == "" ? false :
                                                        registration?.type === "Sales Registration" ? false :
                                                        !profile?.acceptLegalNotice);


  //debug below: openDialog

  const eventDate = new Date(event.eventDate);

  const differenceDays = daysUntilEvent(eventDate);

/*    <Dialog
          fullScreen
          open={openDialog}
          TransitionComponent={Slide}
          TransitionProps={{direction: "up"} as any}
    > */
    //or with maxwidth with possible sizes xs, sm, md, lg, xl

  return (

    <Dialog
          open={openDialog}
          fullWidth={true}
          maxWidth={"lg"}
          TransitionComponent={Slide}
          TransitionProps={{direction: "up"} as any}
    >

    <Box className={classes.container}>

      <Typography
        variant="h6"
        color="textPrimary"
        style={{ marginTop: 20, marginBottom: 20 }}
      >
        Profile
      </Typography>
      <Stepper
         activeStep={activeStep}
         alternativeLabel
         color="primary"
      >
         {steps.map((label) => (
           <Step
              key={label}
              completed = {false}
//              disabled = {false}
           >
              <StepLabel>
                 {label}
              </StepLabel>
          </Step>
         ))
      }
      </Stepper>

     <Box display="flex" justifyContent="flex-end">
            <Button
              style={{ marginLeft: 20, marginTop: 5, marginBottom: 5 }}
              variant="contained"
              color="primary"
              disabled = {activeStep === 0}
              onClick={() => handlePrevious()}
            >
              Previous
            </Button>
           {activeStep <= steps.length - 2 &&
             activeStep >= 0 &&
            <Button
              style={{ marginLeft: 20, marginTop: 5, marginBottom: 5 }}
              variant="contained"
              color="primary"
//              disabled = {activeStep === steps.length}
              onClick={() => handleNext()}
            >
             Next
            </Button>
          }
           {activeStep === steps.length - 1 &&
            <Button
              style={{ marginLeft: 20, marginTop: 5, marginBottom: 5 }}
              variant="contained"
              color="primary"
              onClick={() =>
                authToken &&
                saveProfileInformation(
                  authToken,
                  Config.getInstance().getEventID(),
                  Config.getInstance().getRegistrationID(),
                  profileFactory.fromJSON(mutableProfile),
                  registrationFactory.fromJSON(mutableParticipant),
                )}
            >
             Finish
            </Button>
          }
     </Box>

      <Box style={{ height: 40 }} />


     {activeStep === 0 &&
       <>
        <Typography
          color="textPrimary"
          align="left"
          style={{ fontWeight: "bold" }}
        >
          Allergies & Food restrictions
        </Typography>

          <Typography
             color="textSecondary"
             align="left"
          >
           { differenceDays < 7 ?
                "Since the event takes place within less than 7 days, please reach out to your NC contact person for providing your food restrictions."
                :
                "Please provide any allergies, food restrictions or other comments below."
           }
          </Typography>


        <Box className={classes.row}>
          <Typography
             color="textPrimary"
             align="left"
          >
            No
          </Typography>

          <Switch
             aria-label="show_dietary_needs"
             color="primary"
             disabled={differenceDays < 7 }
             defaultChecked={showDietaryNeeds}
             checked={showDietaryNeeds}
             onChange={(_, value) => {
               setShowDietaryNeeds(value)
               if(!value){
                 setMutableProfile({
                    ...mutableProfile,
                    dietary_needs: "",
                })
             }
           }
           }
          />
          <Typography
             color="textPrimary"
             align="left"
          >
            Yes
          </Typography>
        </Box>

        {showDietaryNeeds &&
          <>
        <Box style={{ height: 10,
                      marginBottom: 10,
                      marginTop: 0 }} />

          <TextField
            label={"Food restrictions (max. 255)"}
            multiline
            rows={4}
            disabled={differenceDays < 7 ? true : false}
            variant="filled"
            color="primary"
            InputProps={{
              disableUnderline: true,
            }}
            inputProps={{
              maxLength: 255,
            }}
            value={mutableProfile?.dietary_needs || ""}
            onChange={(event) =>
              {
                setMutableProfile({
                    ...mutableProfile,
                    dietary_needs: event.target.value,
                })
            }
          }
        />
        </>
      }

        <Box style={{ marginBottom: 10,
                      marginTop: 10 }}>
            <Typography
              color="textPrimary"
              align="left"
              style={{ fontWeight: "bold" }}
            >
              Other Information
            </Typography>
            <Typography color="textSecondary" align="left">
               Please provide some further personal details
            </Typography>
        </Box>
        <TextField
          style={{ marginBottom: 10 }}
          size="small"
          label="Mobile Phone Number *"
          variant="filled"
          InputProps={{
            disableUnderline: true,
          }}
          value={mutableProfile.mobile_phone_number || ""}
          onChange={(event) =>
            setMutableProfile({
              ...mutableProfile,
              mobile_phone_number: event.target.value,
            })
          }
        />

        <Box style={{ marginBottom: 10,
                      marginTop: 10,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between" }}>
          <Box style={{ flex: 1 }}>
            <Typography
              color="textPrimary"
              align="left"
              style={{ fontWeight: "bold" }}
            >
              Legal Notice
            </Typography>
            <Typography color="textSecondary" align="left">
               Please note that your personal details will only be used for internal communication. Food restrictions will be shared with the event venue.
              By checking this box, you agree to these terms.
            </Typography>
          </Box>
          <ProfileSwitch
            checked={mutableProfile?.allow_dietary_needs_sharing}
            onClick={() =>
              setMutableProfile({
                ...mutableProfile,
              allow_dietary_needs_sharing: !mutableProfile.allow_dietary_needs_sharing,
              })
            }
          />
        </Box>


      </>
      }


     {activeStep === 1 &&
       <>
        <Typography
          color="textPrimary"
          align="left"
          style={{ fontWeight: "bold", marginBottom: 10 }}
        >
          Personal Information
        </Typography>
        <Typography color="textSecondary" align="left">
          Your personal contact information can be updated here
        </Typography>

        <Box style={{ height: 10 }} />


        <TextField
          style={{ marginBottom: 10 }}
          size="small"
          label="Name (max. 35)"
          variant="filled"
          InputProps={{
            disableUnderline: true,
          }}
          inputProps={{
              maxLength: 35,
            }}
          value={mutableParticipant?.name || ""}
          onChange={(event) =>
            setMutableParticipant({
              ...mutableParticipant,
              name: event.target.value,
            })
          }
        />


        <TextField
          style={{ marginBottom: 10 }}
          size="small"
          label="Function (max. 35)"
          variant="filled"
          InputProps={{
            disableUnderline: true,
          }}
          inputProps={{
              maxLength: 35,
            }}
          value={mutableParticipant?.title || ""}
          onChange={(event) =>
            setMutableParticipant({
              ...mutableParticipant,
              title: event.target.value,
            })
          }
        />

        <TextField
          style={{ marginBottom: 10 }}
          size="small"
          label="E-Mail Address"
          variant="filled"
          InputProps={{
            disableUnderline: true,
          }}
          value={mutableProfile?.email || ""}
          onChange={(event) =>
            setMutableProfile({
              ...mutableProfile,
              email: event.target.value,
            })
          }
        />

        <Divider style={{marginBottom: 10}}/>

        <TextField
          style={{ marginBottom: 10 }}
          size="small"
          label="Company Brand Name (without legal form such as GmbH, AG, SE, etc.)"
          variant="filled"
          disabled={true}
          InputProps={{
            disableUnderline: true,
          }}
          inputProps={{
              maxLength: 25,
            }}
          value={mutableParticipant?.company || ""}
          onChange={(event) =>
            setMutableParticipant({
              ...mutableParticipant,
              company: event.target.value,
            })
          }
        />

        <TextField
          style={{ marginBottom: 10 }}
          size="small"
          label="Company Address"
          variant="filled"
          disabled={true}
          InputProps={{
            disableUnderline: true,
          }}
          inputProps={{
              maxLength: 255,
            }}
          value={mutableProfile?.address || ""}
          onChange={(event) =>
            setMutableProfile({
              ...mutableProfile,
              address: event.target.value,
            })
          }
        />

        <TextField
          size="small"
          label="Company Phone (Switchboard)"
          variant="filled"
          disabled={true}
          InputProps={{
            disableUnderline: true,
          }}
          value={mutableProfile?.phone_number || ""}
          onChange={(event) =>
            setMutableProfile({
              ...mutableProfile,
              phone_number: event.target.value,
            })
          }
        />

        <Box style={{ marginBottom: 10,
                      marginTop: 20 }}>
            <Typography
              color="textPrimary"
              align="left"
              style={{ fontWeight: "bold" }}
            >
              Legal Notice
            </Typography>
            <Typography color="textSecondary" align="left">
                Due to multiple requests, the above data will only be shared with the participants who are registered for the event. In order to carry out the event in the desired quality, we need your consent to be able to participate.
            </Typography>
        </Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: 10,
            marginTop: 10
          }}
        >
          <Box style={{ flex: 1 }}>
            <Typography color="textPrimary" align="left">
                Agreement
            </Typography>
            <Typography color="textSecondary" align="left">
              By checking this box, you aggree to the above legal notice.
            </Typography>
          </Box>

          <ProfileSwitch
            checked={mutableProfile?.accept_legal_notice}
            onClick={() =>
              setMutableProfile({
                ...mutableProfile,
                accept_legal_notice: !mutableProfile.accept_legal_notice,
              })
            }
          />
        </Box>




        </>
      }

    </Box>
      </Dialog>


);
};
