import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    createStyles,
    makeStyles,
    Switch,
    TextField,
    Theme,
    Typography,
    Checkbox,
    FormGroup,
    FormControlLabel,
    withStyles,
} from "@material-ui/core";
import { Rating } from "@material-ui/lab";
import {useDispatch, useSelector} from "react-redux";
import {retrieveProfile, selectProfile, updateProfile, updateFeedback,} from "../registrations/registrationsSlice";
import {selectToken} from "../authentication/authenticationSlice";
import Config from "../../config";
import {retrieveRegistrations, selectRegistration, selectRegistrations, updateRegistration,} from "../registrations/registrationsSlice";
import {Profile as ProfileType, ProfileFactory, ProfileJSON,} from "../../models/models";
import {Registration, RegistrationFactory, RegistrationJSON} from "../../models/models";
import {useSnackbar} from "notistack";
import {ProfileSwitch} from "../firstLoginFlow/FirstLoginFlowDialog";
import {retrieveEvent, selectEvent} from "../badge/eventSlice";
import {daysUntilEvent} from "../../utils/dateFunctions";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      paddingBottom: 20,
      paddingLeft: 20,
      paddingRight: 20,
      backgroundColor: theme.palette.background.default,
      overflow: "auto",
    },
    row: {
      display: "flex",
      alignItems: "center",
      marginBottom: 20,
    },
    indicator: {
      height: 10,
      width: 10,
      borderRadius: 5,
      backgroundColor: theme.palette.primary.main,
      marginRight: 10,
    },
    name: {
      fontWeight: "bold",
    },
  })
);

export const Feedback = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const profile = useSelector(selectProfile(Config.getInstance().getRegistrationID()));
  const registration = useSelector(selectRegistration(Config.getInstance().getRegistrationID()));
  const registrations = useSelector(selectRegistrations);
  const event = useSelector(selectEvent);

  const authToken = useSelector(selectToken);

  useEffect(() => {
    if (authToken) {
      dispatch(retrieveEvent(authToken, Config.getInstance().getEventID()));
      dispatch(retrieveProfile(authToken,Config.getInstance().getEventID(),Config.getInstance().getRegistrationID()));
      dispatch(retrieveRegistrations(authToken, Config.getInstance().getEventID()));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const profileFactory = new ProfileFactory();
  const registrationFactory = new RegistrationFactory();
  const [mutableProfile, setMutableProfile] = useState<ProfileJSON>(profileFactory.toJSON(profile));
  const [mutableRegistration, setMutableRegistration] = useState<RegistrationJSON>(registrationFactory.toJSON(registration));

  useEffect(() => {
    if (profile) {
      setMutableProfile(profileFactory.toJSON(profile));
    }
    if (profile && profile.feedbackNetworkingOpportunities){
      setRatingValueNetworkingOpportunities(profile.feedbackNetworkingOpportunities);
    }
    if (profile && profile.feedbackExecutiveInteractions){
      setRatingValueExecutiveInteractions(profile.feedbackExecutiveInteractions);
    }
    if (profile && profile.feedbackLocationFood){
      setRatingValueLocationAndFood(profile.feedbackLocationFood);
    }
    if (profile && profile.feedbackTopicsContents){
      setRatingValueContent(profile.feedbackTopicsContents);
    }
    if (profile && profile.feedbackOverallExperience){
      setRatingValueOverall(profile.feedbackOverallExperience);
    }
    if (profile && profile.feedbackInterestedInUpcomingEvent === 'yes'){
      setInterestInUpcomingEvent([true,false,false]);
    }
    else if (profile && profile.feedbackInterestedInUpcomingEvent === 'maybe'){
      setInterestInUpcomingEvent([false,true,false]);
    }
    else if (profile && profile.feedbackInterestedInUpcomingEvent === 'no'){
      setInterestInUpcomingEvent([false,false,true]);
    }
    if (profile && profile.feedbackAdditional){
      setAdditionalFeedback(profile.feedbackAdditional);
    }

  }, [JSON.stringify(profileFactory.toJSON(profile))]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (registration) {
      setMutableRegistration(registrationFactory.toJSON(registration));
    }
  }, [JSON.stringify(registrationFactory.toJSON(registration))]);


  const saveProfileInformation = (
    authToken: string,
    eventID: string,
    registrationID: string,
  ) => {

    const thisProfileFactory = new ProfileFactory()
    const thisProfile = thisProfileFactory.fromJSON(mutableProfile);
    thisProfile.feedbackNetworkingOpportunities = ratingValueNetworkingOpportunities;
    thisProfile.feedbackExecutiveInteractions = ratingValueExecutiveInteractions;
    thisProfile.feedbackTopicsContents = ratingValueContent;
    thisProfile.feedbackLocationFood = ratingValueLocationAndFood;
    thisProfile.feedbackOverallExperience = ratingValueOverall;
    if(interestInUpcomingEvent[0] === true){
      thisProfile.feedbackInterestedInUpcomingEvent = "yes";
    }
    else if (interestInUpcomingEvent[1] === true){
      thisProfile.feedbackInterestedInUpcomingEvent = "maybe";
    }
    else if (interestInUpcomingEvent[2] === true){
      thisProfile.feedbackInterestedInUpcomingEvent = "no";
    }
    else {
      thisProfile.feedbackInterestedInUpcomingEvent = "n/a";
    }
    thisProfile.feedbackAdditional = additionalFeedback;

    dispatch(updateFeedback(authToken, eventID, registrationID, thisProfile));

    enqueueSnackbar("Thanks a lot for providing your feedback.", {
      variant: "success",
    });


  };


  const ratingLabels: { [index: string]: string} = {
    0.5: 'Very Dissatisfied',
    1:   'Very Dissatisfied+',
    1.5: 'Dissatisfied',
    2: 'Dissatisfied+',
    2.5: 'Ok',
    3: 'Ok+',
    3.5: 'Satisifed',
    4: 'Satisfied+',
    4.5: 'Very Satisfied',
    5: 'Very Satisfied+',
  };

  function getRatingLableText(value: number) {
    return `${value} Star${value !==1 ? 's' : ''}, ${ratingLabels[value]}`;
  }

  const [ratingHover, setRatingHover] = useState<number>(-1);


  const [ratingValueNetworkingOpportunities, setRatingValueNetworkingOpportunities] = useState<number | null>(2.5);
  const [ratingHoverNetworkingOpportunities, setRatingHoverNetworkingOpportunities] = useState<number | null>(2.5);

  const [ratingHoverExecutiveInteractions, setRatingHoverExecutiveInteractions] = useState<number> (-1);
  const [ratingValueExecutiveInteractions, setRatingValueExecutiveInteractions] = useState<number | null>(2.5);


  const [ratingValueOverall, setRatingValueOverall] = useState<number | null>(2.5);
  const [ratingHoverOverall, setRatingHoverOverall] = useState<number> (-1);

/*  const [ratingValueAgenda, setRatingValueAgenda] = useState<number | null>(2.5);
  const [ratingHoverAgenda, setRatingHoverAgenda] = useState<number> (-1);*/

  const [ratingValueContent, setRatingValueContent] = useState<number | null>(2.5);
  const [ratingHoverContent, setRatingHoverContent] = useState<number> (-1);

/*  const [ratingValueLocation, setRatingValueLocation] = useState<number | null>(2.5);
  const [ratingHoverLocation, setRatingHoverLocation] = useState<number> (-1);*/

  const [ratingValueLocationAndFood, setRatingValueLocationAndFood] = useState<number | null>(2.5);
  const [ratingHoverLocationAndFood, setRatingHoverLocationAndFood] = useState<number> (-1);

  //yes, maybe, no
  const [interestInUpcomingEvent, setInterestInUpcomingEvent] = useState<[boolean,boolean,boolean]>([false,false,false]);

  const [additionalFeedback, setAdditionalFeedback] = useState<string>("");

  const differenceDays = daysUntilEvent(new Date(event.eventDate));

  return (
    <Box className={classes.container}>

      <Typography
        variant="h6"
        color="textPrimary"
        style={{ marginTop: 20, marginBottom: 20 }}
      >
        Feedback
      </Typography>

      <Box style={{ height: 40 }} />
        <Typography
          color="textPrimary"
          align="left"
          style={{ fontWeight: "bold" }}
        >
          How would you rate your experience at our event?
        </Typography>

        <Typography
           color="textPrimary"
        >
        Networking Opportunities
        </Typography>
        <Typography
           color="textSecondary"
        >
        Meaningful connections made and relevance of networking sessions
        </Typography>
        <Box style={{ marginBottom: 30,
                      marginTop: 10,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center" }}>

           <Rating
              name="ratingNetworkingOpportunities"
              value={ratingValueNetworkingOpportunities}
              precision={0.5}
              size="large"
              max={5}
              onChange={(event, newValue) => {
                setRatingValueNetworkingOpportunities(newValue);
              }}
              onChangeActive={(event, newHover) => {
                setRatingHoverNetworkingOpportunities(newHover);
                setRatingHover(newHover);
              }
            }
           />
        </Box>


        <Typography
           color="textPrimary"
        >
        Executive Interactions
        </Typography>
        <Typography
           color="textSecondary"
        >
        Level of Executives and quality of discussions
        </Typography>
        <Box style={{ marginBottom: 30,
                      marginTop: 0,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center" }}>

           <Rating
              name="ratingExecutiveInteractions"
              value={ratingValueExecutiveInteractions}
              precision={0.5}
              size="large"
              max={5}
              onChange={(event, newValue) => {
                setRatingValueExecutiveInteractions(newValue);
              }}
              onChangeActive={(event, newHover) => {
                setRatingHoverExecutiveInteractions(newHover);
                setRatingHover(newHover);
              }
            }
           />
        </Box>



        <Typography
           color="textPrimary"
        >
        Topics and Contents
        </Typography>
        <Typography
           color="textSecondary"
        >
        Value and importance of topics and insights gained
        </Typography>
        <Box style={{ marginBottom: 30,
                      marginTop: 0,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center" }}>

           <Rating
              name="ratingContent"
              value={ratingValueContent}
              precision={0.5}
              size="large"
              max={5}
              onChange={(event, newValue) => {
                setRatingValueContent(newValue);
              }}
              onChangeActive={(event, newHover) => {
                setRatingHoverContent(newHover);
                setRatingHover(newHover);
              }
            }
           />
        </Box>

        <Typography
           color="textPrimary"
        >
        Location, food & beverages
        </Typography>
        <Typography
           color="textSecondary"
        >
        Suitability of the venue and quality of food and drinks
        </Typography>
        <Box style={{ marginBottom: 30,
                      marginTop: 0,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center" }}>

           <Rating
              name="ratingLocationAndFood"
              value={ratingValueLocationAndFood}
              precision={0.5}
              size="large"
              max={5}
              onChange={(event, newValue) => {
                setRatingValueLocationAndFood(newValue);
              }}
              onChangeActive={(event, newHover) => {
                setRatingHoverLocationAndFood(newHover);
                setRatingHover(newHover);
              }
            }
           />
        </Box>



        <Typography
           color="textPrimary"
        >
        Event Experience
        </Typography>
        <Typography
           color="textSecondary"
        >
        Overall satisfaction with the event
        </Typography>
        <Box style={{ marginBottom: 30,
                      marginTop: 0,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center" }}>

           <Rating
              name="ratingOverall"
              value={ratingValueOverall}
              precision={0.5}
              size="large"
              max={5}
              onChange={(event, newValue) => {
                setRatingValueOverall(newValue);
              }}
              onChangeActive={(event, newHover) => {
                setRatingHoverOverall(newHover);
                setRatingHover(newHover);
              }
            }
           />
        </Box>


        <Typography
           color="textPrimary"
        >
        Would you be interested in attending the next Executive Circle event again?
        </Typography>
        <Box style={{ marginBottom: 30,
                      marginTop: 0,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center" }}>
          <FormGroup row>
            <FormControlLabel
               control={
                   <Checkbox
                      name="interestInEventYes"
                      color="primary"
                      checked={interestInUpcomingEvent[0]}
                      onChange={(event, newValue) => {
                          setInterestInUpcomingEvent([newValue,false,false]);
                      }}
                    />
                  }
               label={<Typography color="textSecondary"> Yes </Typography>}
               labelPlacement="bottom"
           />

            <FormControlLabel
               control={
                   <Checkbox
                      name="interestInEventMaybe"
                      color="primary"
                      checked={interestInUpcomingEvent[1]}
                      onChange={(event, newValue) => {
                          setInterestInUpcomingEvent([false,newValue,false]);
                      }}
                    />
                  }
               label={<Typography color="textSecondary"> Maybe </Typography>}
               labelPlacement="bottom"
           />

            <FormControlLabel
               control={
                   <Checkbox
                      name="interestInEventNo"
                      color="primary"
                      checked={interestInUpcomingEvent[2]}
                      onChange={(event, newValue) => {
                          setInterestInUpcomingEvent([false,false,newValue]);
                      }}
                    />
                  }
               label={<Typography color="textSecondary"> No </Typography>}
               labelPlacement="bottom"
               color="white"
           />

           </FormGroup>
        </Box>



        <Box style={{ height: 10,
                      marginBottom: 10,
                      marginTop: 20 }} />

          <TextField
            label="Additional feedback (max. 2048)"
            multiline
            rows={4}
            size="small"
            variant="filled"
            color="primary"
            InputProps={{
              disableUnderline: true,
            }}
            inputProps={{
              maxLength: 2048,
            }}
            value={additionalFeedback}
            onChange={(event) =>
              setAdditionalFeedback(event.target.value)
            }
        />



      <Box style={{ height: 20 }} />
      <Button
        color="primary"
        variant="contained"
              onClick={() =>
                authToken &&
                saveProfileInformation(
                  authToken,
                  Config.getInstance().getEventID(),
                  Config.getInstance().getRegistrationID(),
                )}
      >
        Save
      </Button>
    </Box>
  );
};
