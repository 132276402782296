import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk, RootState} from "../../app/store";
import {
  Profile,
  ProfileFactory,
  ProfileJSON,
  Registration,
  RegistrationFactory,
  RegistrationJSON,
} from "../../models/models";
import Config from "../../config";

interface RegistrationState {
  isFetching: boolean;
  registrations: RegistrationJSON[];
  profiles: { [key: string]: ProfileJSON };
}

const initialState: RegistrationState = {
  isFetching: false,
  registrations: [],
  profiles: {},
};

export const registrationsSlice = createSlice({
  name: "registrations",
  initialState,
  reducers: {
    storeFetching: (state, action: PayloadAction<boolean>) => {
      state.isFetching = action.payload;
    },
    storeRegistrations: (state, action: PayloadAction<Registration[]>) => {
      state.registrations = action.payload.map((registration) =>
        new RegistrationFactory().toJSON(registration)
      );
    },
    storeProfile: (state, action: PayloadAction<Profile>) => {
      state.profiles[action.payload.registrationID] =
        new ProfileFactory().toJSON(action.payload);
    },
    purgeStore: (state) => {
      state.registrations = initialState.registrations;
    },
  },
});

export const { storeRegistrations, storeProfile, purgeStore } =
  registrationsSlice.actions;

export const retrieveRegistrations =
  (authToken: string, eventID: string): AppThunk =>
  async (dispatch) => {
    try {
      const headers: Headers = new Headers();

      headers.append("Accept", "application/json");
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", `Bearer ${authToken}`);

      let url = `${Config.getInstance().getCoordinationServiceURL()}/api/events/${eventID}/registrations/`;

      fetch(url, {
        method: "GET",
        headers: headers,
      })
        .then((response) => response.json())
        .then((data) => {
          // make sure the returned data is actually array-shaped
          if (!data || !Array.isArray(data)) {
            return;
          }

          // dispatch(purgeStore());

          dispatch(
            storeRegistrations(
              data.map((registrationJSON) =>
                new RegistrationFactory().fromJSON(registrationJSON)
              )
            )
          );
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error(error);
    }
  };

export const updateRegistration =
  (
    authToken: string,
    eventID: string,
    registrationID: string,
    registration: Registration
  ): AppThunk =>
  async (dispatch) => {
    try {
      const headers: Headers = new Headers();

      headers.append("Accept", "application/json");
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", `Bearer ${authToken}`);

      const registrationFactory = new RegistrationFactory();

      let url = `${Config.getInstance().getCoordinationServiceURL()}/api/events/${eventID}/registrations/${registrationID}/`;

      fetch(url, {
        method: "PUT",
        headers: headers,
        body: JSON.stringify(registrationFactory.toJSON(registration)),
      })
        .then((response) => response.json())
        .then((data) => {})
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

export const retrieveProfile =
  (authToken: string, eventID: string, registrationID: string): AppThunk =>
  async (dispatch) => {
    try {
      const headers: Headers = new Headers();

      headers.append("Accept", "application/json");
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", `Bearer ${authToken}`);

      let url = `${Config.getInstance().getCoordinationServiceURL()}/api/events/${eventID}/registrations/${registrationID}/profile/`;

      fetch(url, {
        method: "GET",
        headers: headers,
      })
        .then((response) => response.json())
        .then((data) => {
          const profileFactory = new ProfileFactory();

          dispatch(storeProfile(profileFactory.fromJSON(data)));
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

export const updateProfile =
  (
    authToken: string,
    eventID: string,
    registrationID: string,
    profile: Profile
  ): AppThunk =>
  async (dispatch) => {
    try {
      const headers: Headers = new Headers();

      headers.append("Accept", "application/json");
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", `Bearer ${authToken}`);

      const profileFactory = new ProfileFactory();

      let url = `${Config.getInstance().getCoordinationServiceURL()}/api/events/${eventID}/registrations/${registrationID}/profile/`;

      fetch(url, {
        method: "PUT",
        headers: headers,
        body: JSON.stringify(profileFactory.toJSON(profile)),
      })
        .then((response) => response.json())
        .then((data) => {
          dispatch(storeProfile(profile));
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    }
  };


//contact is the profile and the registration together
export const updateContact =
  (
    authToken: string,
    eventID: string,
    registrationID: string,
    profile: Profile,
    registrations: Registration[]
  ): AppThunk =>
  async (dispatch) => {
    try {
      const headers: Headers = new Headers();

      headers.append("Accept", "application/json");
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", `Bearer ${authToken}`);

      const profileFactory = new ProfileFactory();
      const registrationsJSON = registrations.map((registration) => new RegistrationFactory().toJSON(registration));

      let url = `${Config.getInstance().getCoordinationServiceURL()}/api/events/${eventID}/registrations/${registrationID}/contact/`;

      fetch(url, {
        method: "PUT",
        headers: headers,
        body: JSON.stringify({'registrations': registrationsJSON, 'profile': profileFactory.toJSON(profile)}),
      })
        .then((response) => response.json())
        .then((data) => {

          //transform input type registrationJSON to actual registration class
          dispatch(storeProfile(new ProfileFactory().fromJSON(data.profile)));
          dispatch(storeRegistrations(
                data.registrations.map((registrationJSON: RegistrationJSON) =>
                  new RegistrationFactory().fromJSON(registrationJSON)
                )
              )
            );


        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    }
  };

//update feedback only updates submitted feedback
export const updateFeedback =
  (
    authToken: string,
    eventID: string,
    registrationID: string,
    profile: Profile
  ): AppThunk =>
  async (dispatch) => {
    try {
      const headers: Headers = new Headers();

      headers.append("Accept", "application/json");
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", `Bearer ${authToken}`);

      const profileFactory = new ProfileFactory();

      let url = `${Config.getInstance().getCoordinationServiceURL()}/api/events/${eventID}/registrations/${registrationID}/update_feedback/`;

      fetch(url, {
        method: "PUT",
        headers: headers,
        body: JSON.stringify(profileFactory.toJSON(profile)),
      })
        .then((response) => response.json())
        .then((data) => {
          dispatch(storeProfile(profile));
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    }
  };

export const selectRegistrations = (state: RootState) =>
  state.registrationStore.registrations.map((registrationJSON) =>
    new RegistrationFactory().fromJSON(registrationJSON)
  );

export const selectRegistration =
  (registrationID: string) => (state: RootState) => {
    const filteredRegistrations = state.registrationStore.registrations.filter(
      (registrationJSON) => registrationJSON.id === registrationID
    );

    return filteredRegistrations.length > 0
      ? new RegistrationFactory().fromJSON(filteredRegistrations[0])
      : new RegistrationFactory().init();
  };

export const selectProfile = (registrationID: string) => (state: RootState) => {
  const profileFactory = new ProfileFactory();
  const profileJSON = state.registrationStore.profiles[registrationID];

  return profileJSON
    ? profileFactory.fromJSON(profileJSON)
    : new Profile("1", registrationID, "", "", "", "", "", "", "", "", false, false, false, true, null, null, null, null, null, null, null);
};




export default registrationsSlice.reducer;
