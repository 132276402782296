import React from "react";
import {Box, Button, createStyles, IconButton, makeStyles, Theme, Typography,} from "@material-ui/core";
import {Registration, ThinkTankContent} from "../../models/models";
import LinkedIn from "../../resources/linkedin.svg";
import {openLinkedInProfile} from "../../utils/linkedin";
import {useHistory} from "react-router";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            flex: 1,
            display: "flex",
            flexDirection: "column",
        },
        row: {
            display: "flex",
            alignItems: "center",
            paddingLeft: 10,
            paddingRight: 10,
        },
        indicator: {
            height: 10,
            width: 10,
            borderRadius: 5,
            backgroundColor: theme.palette.primary.main,
            marginRight: 10,
        },
        name: {
            fontWeight: "bold",
        },
        linkedIn: {
            height: 20,
            width: 20,
        },
        thinkTankContent: {
            padding: 10,
            borderRadius: 10,
            backgroundColor: theme.palette.background.paper,
            marginBottom: 20
        }
    })
);

export interface AgendaKeynoteRoundDetailProps {
    name: string;
    title: string;
    tableNumber: number;
    description: string;
    participants: Registration[];
    contents: ThinkTankContent [];
    registrationType: string;
}

export const AgendaKeynoteRoundDetail: React.FC<AgendaKeynoteRoundDetailProps> = ({
                                                                                          name,
                                                                                          title,
                                                                                          tableNumber,
                                                                                          description,
                                                                                          participants,
                                                                                          contents,
                                                                                          registrationType
                                                                                      }) => {
    const classes = useStyles();

    const history = useHistory();

    const openInNewTab = (url: string): void => {
      const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
      if (newWindow) newWindow.opener = null
    }

    return (
        <Box className={classes.container}>
            <Typography
                color="textSecondary"
                align="left"
                style={{marginBottom: 40}}
            >
                {title === "Not seated" ? "Not attending in this round" : description}
            </Typography>
            {title !== "Not seated" &&
            <Typography
                color="textPrimary"
                align="left"
                style={{fontWeight: "bold", marginBottom: 10}}
            >
                Table Nr. {tableNumber}
            </Typography>
          }
        </Box>
    );
};
