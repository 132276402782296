import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk, RootState} from "../../app/store";
import {
    ThinkTankSeating,
    ThinkTankSeatingFactory,
    ThinkTankSeatingJSON,
    ThinkTankTable,
    ThinkTankTableFactory,
    ThinkTankTableJSON,
    ThinkTankRealTable,
    ThinkTankRealTableFactory,
    ThinkTankRealTableJSON,

} from "../../models/models";
import Config from "../../config";

interface EventState {
  isFetching: boolean;
  seatedRegistrations: ThinkTankSeatingJSON[];
  seatedThinkTanks: ThinkTankTableJSON[];
  seatedThinkTankRealTables: ThinkTankRealTableJSON[];
}

const initialState: EventState = {
  isFetching: false,
  seatedRegistrations: [],
  seatedThinkTanks: [],
  seatedThinkTankRealTables: [],
};

export const thinkTankSeatingSlice = createSlice({
  name: "thinkTankSeating",
  initialState,
  reducers: {
    storeFetching: (state, action: PayloadAction<boolean>) => {
      state.isFetching = action.payload;
    },
    storeThinkTankSeatings: (
      state,
      action: PayloadAction<ThinkTankSeating[]>
    ) => {
      const seatedRegistrationFactory = new ThinkTankSeatingFactory();

      state.seatedRegistrations = action.payload.map((seatedRegistration) =>
        seatedRegistrationFactory.toJSON(seatedRegistration)
      );
    },
    storeThinkTanks: (
      state,
      action: PayloadAction<ThinkTank[]>
    ) => {
      const seatedThinkTankFactory = new ThinkTankTableFactory();

      state.seatedThinkTanks = action.payload.map((seatedThinkTank) =>
        seatedThinkTankFactory.toJSON(seatedThinkTank)
      );
    },

    storeThinkTankRealTables: (
      state,
      action: PayloadAction<ThinkTankRealTable[]>
    ) => {
      const seatedThinkTankRealTableFactory = new ThinkTankRealTableFactory();

      state.seatedThinkTankRealTables = action.payload.map((seatedThinkTankRealTable) =>
        seatedThinkTankRealTableFactory.toJSON(seatedThinkTankRealTable)
      );
    },

    purgeStore: (state) => {
      state.isFetching = false;
      state.seatedRegistrations = [];
      state.seatedThinkTanks = [];
      state.seatedThinkTankRealTables = [];
    },
  },
});


export const { storeFetching, storeThinkTankSeatings, storeThinkTanks, storeThinkTankRealTables, purgeStore } =
  thinkTankSeatingSlice.actions;


export const retrieveThinkTankSeating =
  (authToken: string, eventID: string): AppThunk =>
  async (dispatch) => {
    try {
      const headers: Headers = new Headers();

      headers.append("Accept", "application/json");
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", `Bearer ${authToken}`);

      let url = `${Config.getInstance().getCoordinationServiceURL()}/api/events/${eventID}/think_tank_seating/`;

      dispatch(storeFetching(true));

      fetch(url, {
        method: "GET",
        headers: headers,
      })
        .then((response) => response.json())
        .then((data) => {
          dispatch(purgeStore());

          dispatch(
            storeThinkTankSeatings(
              data["registrations"].map(
                (seatedRegistrationJson: ThinkTankSeatingJSON) =>
                  new ThinkTankSeatingFactory().fromJSON(
                    seatedRegistrationJson
                  )
              )
            )
          );


          dispatch(
            storeThinkTanks(
              data["think_tanks"].map(
                (seatedThinkTankJson: ThinkTankJSON) =>
                  new ThinkTankTableFactory().fromJSON(seatedThinkTankJson)
              )
            )
          );

          dispatch(
            storeThinkTankRealTables(
              data["think_tank_real_tables"].map(
                (seatedThinkTankRealTableJson: ThinkTankRealTableJSON) =>
                  new ThinkTankRealTableFactory().fromJSON(seatedThinkTankRealTableJson)
              )
            )
          );


         dispatch(storeFetching(false));

        })
        .catch((error) => {
          console.log(error);
          dispatch(storeFetching(false));
        });
    } catch (error) {
      dispatch(storeFetching(false));
      console.error(error);
    }
  };

export const selectThinkTankSeatings = (state: RootState) => {
  const seatedRegistationFactory = new ThinkTankSeatingFactory();

  return state.thinkTankRealTableSeatingStore.seatedRegistrations.map(
    (seatedRegistrationJSON) =>
      seatedRegistationFactory.fromJSON(seatedRegistrationJSON)
  );

/*  return state.thinkTankSeatingStore.seatedRegistrations.map(
    (seatedRegistrationJSON) =>
      seatedRegistationFactory.fromJSON(seatedRegistrationJSON)
  );*/
};



export const selectThinkTanks = (state: RootState) => {
  const seatedThinkTankFactory = new ThinkTankTableFactory();

  return state.thinkTankSeatingStore.seatedThinkTanks.map(
    (seatedThinkTankJSON) =>
      seatedThinkTankFactory.fromJSON(seatedThinkTankJSON)
  );
};

export const selectThinkTankRealTables = (state: RootState) => {
  const seatedThinkTankRealTableFactory = new ThinkTankRealTableFactory();

  return state.thinkTankRealTableSeatingStore.seatedThinkTankRealTables.map(
    (seatedThinkTankRealTableJSON) =>
      seatedThinkTankRealTableFactory.fromJSON(seatedThinkTankRealTableJSON)
  );
};

export const selectThinkTankSeatingIsFetching = (state: RootState) =>
  state.thinkTankSeatingStore.isFetching;


export default thinkTankSeatingSlice.reducer;
