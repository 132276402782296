import {useSelector} from "react-redux";
import {
    Box,
    createStyles,
    makeStyles,
    Theme
} from "@material-ui/core";
import {ThinkTankKeyword, ThinkTankContent} from "../../models/models";
import {ThinkTankKeywordItem, ThinkTankKeywordItemNew} from "./ThinkTankKeywordItem";
import {selectEvent} from "../badge/eventSlice";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    thinkTankKeywordContainer: {
      flex: 1,
      display: "flex",
      alignItems: "left",
      flexWrap: 'wrap',
      flexDirection: "row",
      backgroundColor: 'transparent',
    },
  })
);

interface ThinkTankKeywordsScreenProps {
  parentThinkTank: ThinkTankContent;
  updateParentThinkTank: (thinkTankKeyword: ThinkTankKeyword) =>  void;
}

export const ThinkTankKeywordsScreen: React.FC<ThinkTankKeywordsScreenProps> = ( {
  parentThinkTank,
  updateParentThinkTank,
}) => {

  const classes = useStyles();

  const event = useSelector(selectEvent);
  const today = new Date();
  const eventDate = new Date(event.eventDate);
  eventDate.setHours(23,59,59); //set time to the end of the day
  const diffDays = (eventDate-today)/1000/3600/24

  return (
    <Box className={classes.thinkTankKeywordContainer}>
        {diffDays >= 7 &&
         <ThinkTankKeywordItemNew
           parentThinkTank={parentThinkTank}
           updateParentThinkTank={updateParentThinkTank}
         />
       }

       {parentThinkTank.keywords.map((thinkTankKeyword) =>
             {return (<ThinkTankKeywordItem
                         thinkTankKeyword={thinkTankKeyword}
                      />)}
                    )
                  }
    </Box>
  );
};
