import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk, RootState} from "../../app/store";
import {ThinkTankTableFactory,
        ThinkTankTable,
        ThinkTankTableJSON,
        ThinkTankDynamicContent,
        ThinkTankDynamicContentFactory,} from "../../models/models";
import Config from "../../config";

interface ThinkTankState {
  isFetching: boolean;
  thinkTanks: ThinkTankTableJSON[];
}

const initialState: ThinkTankState = {
  isFetching: true,
  thinkTanks: [],
};

export const thinkTankSlice = createSlice({
  name: "thinkTanks",
  initialState,
  reducers: {
    storeFetching: (state, action: PayloadAction<boolean>) => {
      state.isFetching = action.payload;
    },
    storeThinkTanks: (state, action: PayloadAction<ThinkTankTable[]>) => {
      const thinkTankFactory = new ThinkTankTableFactory();

      state.thinkTanks = action.payload.map((thinkTank) =>
        thinkTankFactory.toJSON(thinkTank)
      );
    },
    purgeStore: (state) => {
      state.isFetching = false;
      state.thinkTanks = initialState.thinkTanks;
    },
  },
});

export const { storeFetching, storeThinkTanks, purgeStore } = thinkTankSlice.actions;

export const retrieveThinkTanks =
  (authToken: string, eventID: string): AppThunk =>
  async (dispatch) => {

    try {
      const headers: Headers = new Headers();

      headers.append("Accept", "application/json");
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", `Bearer ${authToken}`);

      dispatch(storeFetching(true));

      let url = `${Config.getInstance().getCoordinationServiceURL()}/api/events/${eventID}/think_tanks/`;

      fetch(url, {
        method: "GET",
        headers: headers,
      })
        .then((response) => response.json())
        .then((data) => {
          // make sure the returned data is actually array-shaped
          if (!data || !Array.isArray(data)) {
            return;
          }

          dispatch(purgeStore());

          dispatch(
            storeThinkTanks(
              data.map((thinkTankJSON) =>
                new ThinkTankTableFactory().fromJSON(thinkTankJSON)
              )
            )
          );
         dispatch(storeFetching(false));
        })
        .catch((error) => {
          dispatch(storeFetching(false));
          console.error(error);
        });
    } catch (error) {
      dispatch(storeFetching(false));
      console.error(error);
    }
  };

export const updateThinkTankDynamicContent =
  (
    authToken: string,
    eventId: string,
    registrationId: string,
    thinkTankDynamicContents: ThinkTankDynamicContent[]
  ): AppThunk =>
  async (dispatch) => {
    console.log("received");
    try {
      const headers: Headers = new Headers();

      headers.append("Accept", "application/json");
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", `Bearer ${authToken}`);

      const thinkTankDynamicContentFactory = new ThinkTankDynamicContentFactory();

      let url = `${Config.getInstance().getCoordinationServiceURL()}/api/events/${eventId}/registrations/${registrationId}/update_think_tank_dynamic_content/`;

      fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(
          thinkTankDynamicContents.map(
            (thinkTankDynamicContent) => thinkTankDynamicContentFactory.toJSON(thinkTankDynamicContent)
          )
        )
      })
        .then((response) => response.json())
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    }
  };

export const selectThinkTanks = (state: RootState) =>
  state.thinkTankStore.thinkTanks.map((thinkTankJSON) =>
    new ThinkTankTableFactory().fromJSON(thinkTankJSON)
  );

export const selectThinkTanksIsFetching = (state: RootState) =>
  state.thinkTankStore.isFetching;


export default thinkTankSlice.reducer;
