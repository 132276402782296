import {AgendaElement, AgendaElementType} from "../models/models";

export const getClosestAgendaElementToDate = (
  agendaElements: AgendaElement[],
  currentDate: Date,
  eventDate: Date
): AgendaElement | null => {
  // Only display live information on the event day - otherwise we will simply display information about the first
  // event item that has additional information
  //debugging
  if (
    currentDate.getDate() !== eventDate.getDate() ||
    currentDate.getMonth() !== eventDate.getMonth() ||
    currentDate.getFullYear() !== eventDate.getFullYear()
  ) {
    const relevantAgendaElements = agendaElements.filter(
      (agendaElement) =>
        agendaElement.type === AgendaElementType.KeynoteRound ||
        agendaElement.type === AgendaElementType.ThinkTankRound ||
        agendaElement.type === AgendaElementType.DinnerRound
    );

    return relevantAgendaElements.length > 0 ? relevantAgendaElements[0] : null;
  }

  // We only need to take agenda elements into account that are not over yet
  //also, only show relevant agenda elements, i.e., think tank or dinner rounds
  const relevantAgendaElementsByProximity = agendaElements
    .filter((agendaElement) => {
        return agendaElement.type === AgendaElementType.KeynoteRound ||
               agendaElement.type === AgendaElementType.ThinkTankRound ||
               agendaElement.type === AgendaElementType.DinnerRound
    })
    .filter((agendaElement) => {
      const elementDate = new Date(agendaElement.endTime);
//      console.log("test", elementDate);
//      console.log("currentDate", currentDate);

      const minutesCurrentDate =
        currentDate.getMinutes() + currentDate.getHours() * 60;
      const minutesElementDate =
        elementDate.getMinutes() + elementDate.getHours() * 60 - 10;  //set time to minus 10 minutes, such that next element is always displayed 10 minutes before current round ends

      return minutesElementDate > minutesCurrentDate;
    })
    .sort((first, second) => {
      const firstDate = new Date(first.startTime);
      const secondDate = new Date(second.startTime);

      const minutesFirstDate =
        firstDate.getMinutes() + firstDate.getHours() * 60;
      const minutesSecondDate =
        secondDate.getMinutes() + secondDate.getHours() * 60;

      return minutesFirstDate > minutesSecondDate
        ? 1
        : minutesFirstDate < minutesSecondDate
        ? -1
        : 0;
    });


  if (relevantAgendaElementsByProximity.length > 0) {
    return relevantAgendaElementsByProximity[0];
  }

  return agendaElements[agendaElements.length - 1];
};
