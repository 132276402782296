import {Action, combineReducers, configureStore, ThunkAction,} from "@reduxjs/toolkit";
import {persistReducer, persistStore} from "redux-persist";
import localForage from "localforage";

import authenticationReducer from "../screens/authentication/authenticationSlice";
import registrationReducer from "../screens/registrations/registrationsSlice";
import eventReducer from "../screens/badge/eventSlice";
import thinkTankReducer from "../screens/thinkTanks/thinkTankSlice";
import thinkTankPrioritiesReducer from "../screens/thinkTanks/thinkTankPrioritiesSlice";
import thinkTankSeatingReducer from "../screens/badge/thinkTankSeatingSlice";
import thinkTankRealTableSeatingReducer from "../screens/badge/thinkTankSeatingSlice";
import dinnerSeatingReducer from "../screens/badge/dinnerSeatingSlice";
import priorityReducer from "../screens/priorities/prioritiesSlice";
import notificationReducer from "../screens/profile/notificationSlice";

/*
const migrations: any = {
  1: (state: RootState) => {
    return {
      ...state,
      competitorStore: {
        ...state.competitorStore,
        queue: state.competitorStore.queue || [],
      },
    };
  },
};
 */

const persistConfig = {
  key: "store",
  storage: localForage,
  version: 1,
  // migrate: createMigrate(migrations, { debug: false }),
  //blacklist: ["notificationStore"],
  whitelist: [],
};

const rootReducer = combineReducers({
  authenticationStore: authenticationReducer,
  registrationStore: registrationReducer,
  eventStore: eventReducer,
  thinkTankStore: thinkTankReducer,
  thinkTankPrioritiesStore: thinkTankPrioritiesReducer,
  thinkTankSeatingStore: thinkTankSeatingReducer,
  thinkTankRealTableSeatingStore: thinkTankRealTableSeatingReducer,
  dinnerSeatingStore: dinnerSeatingReducer,
  priorityStore: priorityReducer,
  notificationStore: notificationReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
});

const persistor = persistStore(store);

export { store, persistor };

export type RootState = ReturnType<typeof store.getState>;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
