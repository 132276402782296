import React, {useEffect} from "react";
import {Box, Button, createStyles, IconButton, makeStyles, Theme, Typography,} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {Registration} from "../../models/models";
import Config from "../../config";
import LinkedIn from "../../resources/linkedin.svg";
import {useHistory} from "react-router";
import {openLinkedInProfile} from "../../utils/linkedin";
import {selectToken} from "../authentication/authenticationSlice";
import {retrieveRegistrations, selectRegistration, selectRegistrations,} from "../registrations/registrationsSlice";
import {retrieveProfile, selectProfile} from "../registrations/registrationsSlice";
import {retrieveEvent, selectEvent} from "../badge/eventSlice";
import {FirstLoginFlowDialog} from "../firstLoginFlow/FirstLoginFlowDialog";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      backgroundColor: theme.palette.background.default,
      overflow: "auto",
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 20,
    },
    row: {
      display: "flex",
      alignItems: "center",
      paddingLeft: 10,
      paddingRight: 10,
      paddingTop: 10,
      paddingBottom: 10,
      marginBottom: 10,
      borderRadius: 10,
    },
    indicator: {
      height: 10,
      width: 10,
      borderRadius: 5,
      backgroundColor: theme.palette.primary.main,
      marginRight: 10,
    },
    name: {
      fontWeight: "bold",
    },
    linkedIn: {
      height: 20,
      width: 20,
    },
  })
);

export const Registrations = () => {
  const classes = useStyles();

  const history = useHistory();

  const dispatch = useDispatch();

  const authToken = useSelector(selectToken);

  useEffect(() => {
    if (authToken) {
      dispatch(retrieveRegistrations(authToken, Config.getInstance().getEventID()));
      dispatch(retrieveEvent(authToken, Config.getInstance().getEventID()));
      dispatch(retrieveProfile(authToken,Config.getInstance().getEventID(),Config.getInstance().getRegistrationID()));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Hide business partner registrations
  const participants = useSelector(selectRegistrations)
      .filter(registration => registration.type === "Executive Registration");

  const registrations = useSelector(selectRegistrations);
//Note: cancellations are actually not even handed over by API
  const participationStatusMap: { [key: string]: string } = {
    "100% Confirmed": "Accepted",
    "REGI IN - re-booking": "Accepted",
    "90% Confirmed": "Accepted",
    "Cancellation - sick": "Cancelled",
    "Cancellation - other important meeting": "Cancelled",
    "Cancellation - other reason": "Cancelled",
    "Cancellation - no reason": "Cancelled",
    "100% Confirmed - no show": "Cancelled",
    "50% Confirmed": "Cancelled",
    "at Event": "Checked In",
  };

  const colorMap: { [key: string]: string } = {
    Accepted: "#1DACD6",
    Tentative: "#fbc531",
    Declined: "#FE5E55",
    Cancelled: "#FE5E55",
  };

  const groupedRegistrations = participants
    .map((registration) => ({
      ...registration,
      participationStatus:
        participationStatusMap[registration.participationStatus],
    }))
    .reduce(
      (acc, registration) => ({
        ...acc,
        [registration.participationStatus]: acc[
          registration.participationStatus
        ]
          ? [...acc[registration.participationStatus], registration]
          : [registration],
      }),
      {} as { [key: string]: Registration[] }
    );

  const openInNewTab = (url: string): void => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  const registration = useSelector(
    selectRegistration(Config.getInstance().getRegistrationID())
  );
  const profile = useSelector(
    selectProfile(Config.getInstance().getRegistrationID())
  );

  const event = useSelector(selectEvent);

  return (
    <Box className={classes.container}>

      <FirstLoginFlowDialog
           authToken={authToken || ""}
           registrations={registrations}
           profile={profile}
           event={event}
      />

      <Typography variant="h6" color="textPrimary" style={{ marginTop: 20 }}>
        Registrations
      </Typography>
      {Object.keys(groupedRegistrations)
        .sort((first: string, second: string) => {
          if (first === "Checked In") return -1;
          if (second === "Checked In") return 1;

          return first.localeCompare(second);
        })
        .map((key: string) => (
          <>
            <Typography
              color="textPrimary"
              align="left"
              style={{ fontWeight: "bold", marginBottom: 10, marginTop: 20 }}
            >
              {key === "Cancelled" ? "" : key || "Unknown"}
            </Typography>
            {key !== "Cancelled" && groupedRegistrations[key]
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((registration) => (
                <Button
                  className={classes.row}
                  onClick={() =>
                    history.push({
                      pathname: `/app/registrations/${registration.id}`,
                      search: window.location.search,
                    })
                  }
                >
                  <Box
                    className={classes.indicator}
                    style={{ backgroundColor: colorMap[key] }}
                  />
                  <Box>
                    <Typography
                      align="left"
                      color="textPrimary"
                      className={classes.name}
                    >
                      {registration.name}
                    </Typography>
                    <Typography align="left" color="textSecondary">
                      {registration.title}, {registration.company}
                    </Typography>
                  </Box>
                  <Box style={{ flex: 1 }} />
                  <IconButton
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                      event.stopPropagation();
                      openInNewTab(registration.linkedinUrl);
                    }}
                   disabled={registration.linkedinUrl === ""}
                  >
                   {(registration?.linkedinUrl === "") ? "" :
                    <img
                      alt="logo"
                      className={classes.linkedIn}
                      src={LinkedIn}
                    />
                  }
                  </IconButton>
                </Button>
              ))}
          </>
        ))}
    </Box>
  );
};
