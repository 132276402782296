import React, {useEffect, useMemo, useState} from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    createStyles,
    makeStyles,
    Theme,
    Typography,
    IconButton,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMoreRounded";
import ReplayIcon from "@material-ui/icons/Replay";

import {useDispatch, useSelector} from "react-redux";
import {FormattedTime} from "react-intl";
import {AgendaElement, AgendaElementType, Registration, ThinkTankTable, ThinkTankRealTable} from "../../models/models";
import {AgendaThinkTankRoundDetail} from "./AgendaThinkTankRoundDetail";
import {AgendaKeynoteRoundDetail} from "./AgendaKeynoteRoundDetail";
import {
    retrieveThinkTankSeating,
    selectThinkTankSeatings,

    selectThinkTanks,
    selectThinkTankRealTables,
} from "../badge/thinkTankSeatingSlice";
import {retrieveRegistrations, selectRegistration, selectRegistrations,} from "../registrations/registrationsSlice";
import Config from "../../config";
import {selectToken} from "../authentication/authenticationSlice";
import {retrieveEvent, selectEvent} from "../badge/eventSlice";
import {retrieveDinnerSeating, selectDinnerSeatings,} from "../badge/dinnerSeatingSlice";
import {AgendaDinnerRoundDetail} from "./AgendaDinnerRoundDetail";
import {retrieveProfile, selectProfile} from "../registrations/registrationsSlice";
import {FirstLoginFlowDialog} from "../firstLoginFlow/FirstLoginFlowDialog"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      paddingBottom: 20,
      paddingLeft: 10,
      paddingRight: 10,
      backgroundColor: theme.palette.background.default,
      overflow: "auto",
    },
    indicator: {
      height: 10,
      width: 10,
      borderRadius: 5,
      backgroundColor: theme.palette.primary.main,
      marginLeft: 10,
      marginRight: 10,
      marginTop: 5,
    },
    name: {
      fontWeight: "bold",
    },
    icon: {
      color: theme.palette.text.primary,
    },
    row: {
      display: "flex",
      flex: 1,
      position: "relative",
    },
    line: {
      position: "absolute",
      width: 2,
      left: "calc(50% - 1px)",
      backgroundColor: theme.palette.primary.main,
      top: 6,
      bottom: -70,
      zIndex: 1000,
    },
    spacer: {
      flex: 1,
    },
    accordionCollapsed: {
      backgroundColor: "rgba(0, 0, 0, 0)",
      transition: "background-color 500ms",
      boxShadow: "none",
      "&::before": {
        display: "none",
      },
    },
    accordionExpanded: {
      borderRadius: 10,
      backgroundColor: theme.palette.background.paper,
      boxShadow: "none",
      "&::before": {
        display: "none",
      },
    },
  })
);

export const Agenda = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const [expandedSection, setExpandedSection] = useState<number>();

  const seatedRegistrations = useSelector(selectThinkTankSeatings);
  const seatedThinkTanks = useSelector(selectThinkTanks);
  const seatedThinkTankRealTables = useSelector(selectThinkTankRealTables);

  const dinnerSeatings = useSelector(selectDinnerSeatings);
  const registrations = useSelector(selectRegistrations);

  const registration = useSelector(
    selectRegistration(Config.getInstance().getRegistrationID())
  );
  const profile = useSelector(
    selectProfile(Config.getInstance().getRegistrationID())
  );

  const event = useSelector(selectEvent);

  const participants = useSelector(selectRegistrations);
  const authToken = useSelector(selectToken);

  useEffect(() => {
    if (authToken) {
      dispatch(retrieveEvent(authToken, Config.getInstance().getEventID()));
      dispatch(retrieveRegistrations(authToken, Config.getInstance().getEventID()));
      dispatch(retrieveThinkTankSeating(authToken, Config.getInstance().getEventID()));
      dispatch(retrieveDinnerSeating(authToken, Config.getInstance().getEventID()));
      dispatch(retrieveProfile(authToken,Config.getInstance().getEventID(),Config.getInstance().getRegistrationID()));
    }
  }, [authToken]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleReload = () => {
    if (authToken) {
      dispatch(retrieveEvent(authToken, Config.getInstance().getEventID()));
      dispatch(retrieveDinnerSeating(authToken, Config.getInstance().getEventID()));
      dispatch(retrieveThinkTankSeating(authToken, Config.getInstance().getEventID()));
      dispatch(retrieveDinnerSeating(authToken, Config.getInstance().getEventID()));
      dispatch(retrieveProfile(authToken,Config.getInstance().getEventID(),Config.getInstance().getRegistrationID()));
    }
  };

  const thinkTankSeatings: ThinkTankSeating[] = useMemo(() => {
    const userSeatings = seatedRegistrations.filter(
      (registration) =>
        registration.profile.id === Config.getInstance().getRegistrationID()
    );
    return userSeatings.sort((a: ThinKTankSeating, b: ThinkTankSeating) =>
      a.round > b.round ? 1 : a.round < b.round ? -1 : 0
    );
  }, [seatedRegistrations.length]); // eslint-disable-line react-hooks/exhaustive-deps


  const participantsByRound: Registration[][] = useMemo(() => {
    const participantsByID = participants.reduce(
      (acc, Registration) => ({
        ...acc,
        [Registration.id]: Registration,
      }),
      {} as { [key: string]: Registration }
    );

    const userSeatings = seatedRegistrations.filter(
      (registration) =>
        registration.profile.id === Config.getInstance().getRegistrationID()
    );

    const thinkTankRealTableIDs = userSeatings.map(
      (thisSeating) => thisSeating.thinkTankRealTable.id
    );

    const participantsByRound: Registration[][] = [];

    const participantsOfUserTopics = seatedRegistrations.filter(
      (registration) =>
        thinkTankRealTableIDs.includes(registration.thinkTankRealTable.id) &&
        registration.profile.id !== Config.getInstance().getRegistrationID() &&
        registration.thinkTankRealTable.thinkTankTable !== null
    );


    for (const registration of participantsOfUserTopics) {
      const nextRegistration = participantsByID[registration.profile.id]
        ? [participantsByID[registration.profile.id]]
        : [];

      participantsByRound[registration.round] = participantsByRound[
        registration.round
      ]
        ? [...participantsByRound[registration.round], ...nextRegistration]
        : nextRegistration;
    }

    participantsByRound.map((participants) =>
      participants.sort((a, b) => a.name.localeCompare(b.name))
    );

    return participantsByRound;
    // eslint-disable-line react-hooks/exhaustive-deps
  }, [seatedThinkTankRealTables.length, participants.length, seatedRegistrations.length ]);


  const getThinkTankName = (thinkTankSeating) => {
    if(thinkTankSeating.thinkTankRealTable.thinkTankTable === null){
      return "No name"
    }
    return thinkTankSeating.thinkTankRealTable.thinkTankTable.name
  }

  const getThinkTankTitle = (thinkTankSeating) => {
    if(thinkTankSeating.thinkTankRealTable.thinkTankTable === null){
      return "No title"
    }
    return thinkTankSeating.thinkTankRealTable.thinkTankTable.title
  }

  const getThinkTankTableNumber = (thinkTankSeating) => {
    if(thinkTankSeating.thinkTankRealTable.thinkTankTable === null){
      return 0
    }
    return thinkTankSeating.thinkTankRealTable.tableNumber
  }

  const getThinkTankContents = (thinkTankSeating) => {
    if(thinkTankSeating.thinkTankRealTable.thinkTankTable === null){
      return []
    }
    return thinkTankSeating.thinkTankRealTable.thinkTankTable.contents
  }


  const thinkTankRoundOneDetail = (
    <AgendaThinkTankRoundDetail
      name = {
        thinkTankSeatings.length > 0 ? getThinkTankName(thinkTankSeatings[0]) : "No name"
      }
      title  = {
        thinkTankSeatings.length > 0 ? getThinkTankTitle(thinkTankSeatings[0]) : "No title"
      }
      tableNumber = {
        thinkTankSeatings.length > 0 ? getThinkTankTableNumber(thinkTankSeatings[0]) : 0
      }
      description={
        "Presentation of the most voted topics, followed by group discussions according to the selected topic per table."
      }
      participants={
        participantsByRound.length > 0 ? participantsByRound[0] : []
      }
      contents={
        thinkTankSeatings.length > 0 ? getThinkTankContents(thinkTankSeatings[0]) : []
      }
      registrationType={registration?.type || ""}
    />
  );


  const thinkTankRoundTwoDetail = (
    <AgendaThinkTankRoundDetail
      name = {
        thinkTankSeatings.length > 1 ? getThinkTankName(thinkTankSeatings[1]) : "No name"
      }
      title  = {
        thinkTankSeatings.length > 1 ? getThinkTankTitle(thinkTankSeatings[1]) : "No title"
      }
      tableNumber = {
        thinkTankSeatings.length > 1 ? getThinkTankTableNumber(thinkTankSeatings[1]) : 0
      }
      description={
        "Second round of open exchange with a new set of topics specially curated for you."
      }
      participants={
        participantsByRound.length > 1 ? participantsByRound[1] : []
      }
      contents={
        thinkTankSeatings.length > 1 ? getThinkTankContents(thinkTankSeatings[1]) : []
      }
      registrationType={registration?.type || ""}
    />
  );

  const keynoteRoundOneDetail = (
    <AgendaKeynoteRoundDetail
      name = {
        thinkTankSeatings.length > 0 ? getThinkTankName(thinkTankSeatings[0]) : "No name"
      }
      title  = {
        thinkTankSeatings.length > 0 ? getThinkTankTitle(thinkTankSeatings[0]) : "No title"
      }
      tableNumber = {
        thinkTankSeatings.length > 0 ? getThinkTankTableNumber(thinkTankSeatings[0]) : 0
      }
      description={
        "A special and interesting Keynote Presentation from one of our invited Executives."
      }
      participants={
        participantsByRound.length > 0 ? participantsByRound[0] : []
      }
      contents={
        thinkTankSeatings.length > 0 ? getThinkTankContents(thinkTankSeatings[0]) : []
      }
      registrationType={registration?.type || ""}
    />
  );

  const keynoteRoundTwoDetail = (
    <AgendaKeynoteRoundDetail
      name = {
        thinkTankSeatings.length > 1 ? getThinkTankName(thinkTankSeatings[1]) : "No name"
      }
      title  = {
        thinkTankSeatings.length > 1 ? getThinkTankTitle(thinkTankSeatings[1]) : "No title"
      }
      tableNumber = {
        thinkTankSeatings.length > 1 ? getThinkTankTableNumber(thinkTankSeatings[1]) : 0
      }
      description={
        "Another insightful Keynote Presentation from one of our participants."
      }
      participants={
        participantsByRound.length > 1 ? participantsByRound[1] : []
      }
      contents={
        thinkTankSeatings.length > 1 ? getThinkTankContents(thinkTankSeatings[1]) : []
      }
      registrationType={registration?.type || ""}
    />
  );

  const focusedSeatingRound0 =   dinnerSeatings.filter((seating) => seating.round === 0 && (seating.registrationID === registration?.id || ""));
  const focusedSeatingRound1 =   dinnerSeatings.filter((seating) => seating.round === 1 && (seating.registrationID === registration?.id || ""));
  const focusedTableIdRound0 = focusedSeatingRound0[0]
                                 ? focusedSeatingRound0[0].tableID
                                 : 0
  const focusedTableIdRound1 = focusedSeatingRound1[0]
                                 ? focusedSeatingRound1[0].tableID
                                 : 0

  let focusedParticipationRound0 = false;
  let focusedParticipationRound1 = false;
  focusedSeatingRound0.length > 0 ? focusedParticipationRound0 = true : focusedParticipationRound0 = false;
  focusedSeatingRound1.length > 0 ? focusedParticipationRound1 = true : focusedParticipationRound1 = false;


  const dinnerRoundOneDetail = (
    <AgendaDinnerRoundDetail
      description="Experience premium dinner service with a bespoke 5-course menu in an exclusive venue."
      dinnerSeatings={dinnerSeatings.filter((seating) => seating.round === 0)}
      focusedRegistrationID={registration?.id || ""}
      focusedBusinessPartnerID={registration?.businessPartner || ""}
      focusedTableID={focusedTableIdRound0}
      focusedParticipation={focusedParticipationRound0}

    />
  );
  const dinnerRoundTwoDetail = (
    <AgendaDinnerRoundDetail
      description="Enjoy the main dish and dessert while seated with another set of executives."
      dinnerSeatings={dinnerSeatings.filter((seating) => seating.round === 1)}
      focusedRegistrationID={registration?.id || ""}
      focusedBusinessPartnerID={registration?.businessPartner || ""}
      focusedTableID={focusedTableIdRound1}
      focusedParticipation={focusedParticipationRound1}
      />
  );

  const agenda: AgendaElement[] = [
    new AgendaElement(
      "1",
      AgendaElementType.Other,
      "Registration & Informal Networking",
      "Your expected arrival time at the venue. We welcome, register, and guide you to the networking area - a perfect opportunity to meet and connect with other executives.",
      "2021-11-03T13:45:00.000Z",
      "2021-11-03T14:10:00.000Z"
    ),
    new AgendaElement(
      "1",
      AgendaElementType.Other,
      "Welcome Remarks & Agenda of the Day",
      "We give you an overview of the Executive Circle, present the timetable and introduce the speaker.",
      "2021-11-03T14:10:00.000Z",
      "2021-11-03T14:30:00.000Z"
    ),
    new AgendaElement(
      "1",
      AgendaElementType.KeynoteRound,
      "1st Keynote Presentation and Q&A",
      "A special and interesting Keynote Presentation from one of our invited Executives.",
      "2021-11-03T14:30:00.000Z",
      "2021-11-03T15:00:00.000Z",
      keynoteRoundOneDetail   //display thinktankroundone already during Keyonote, as they will be at the same table
    ),
    new AgendaElement(
      "1",
      AgendaElementType.ThinkTankRound,
      "Content Analysis & Focus Discussion Round 1",
      "",
      "2021-11-03T15:00:00.000Z",
      "2021-11-03T16:10:00.000Z",
      thinkTankRoundOneDetail
    ),
    new AgendaElement(
      "1",
      AgendaElementType.Other,
      "Networking Break",
      "Short break in the foyer with refreshments. An opportunity to continue the discussions and connect with other executives.",
      "2021-11-03T16:10:00.000Z",
      "2021-11-03T16:30:00.000Z"
    ),
    new AgendaElement(
      "1",
      AgendaElementType.KeynoteRound,
      "2nd Keynote and Q&A",
      "Another insightful Keynote Presentation from one of our participants.",
      "2021-11-03T16:30:00.000Z",
      "2021-11-03T17:00:00.000Z",
      keynoteRoundTwoDetail   //display thinktankroundtwo already during Keyonote, as they will be at the same table
    ),
    new AgendaElement(
      "1",
      AgendaElementType.ThinkTankRound,
      "Focus Discussion Round 2",
      "",
      "2021-11-03T17:00:00.000Z",
      "2021-11-03T18:10:00.000Z",
      thinkTankRoundTwoDetail
    ),
    new AgendaElement(
      "1",
      AgendaElementType.Other,
      "Summary, Recap & Outlook",
      "Summary of the Focus Discussion Round 2, followed by an overview of the upcoming Executive Circle events.",
      "2021-11-03T18:10:00.000Z",
      "2021-11-03T18:20:00.000Z"
    ),
    new AgendaElement(
      "1",
      AgendaElementType.DinnerRound,
      "Executive Dinner Circle Round 1",
      "Experience premium dinner service with a bespoke 5-course menu in an exclusive venue.",
      "2021-11-03T18:30:00.000Z",
      "2021-11-03T19:10:00.000Z",
      registration?.type !== "Executive Registration"
        ? dinnerRoundOneDetail
        : dinnerRoundOneDetail
    ),
    new AgendaElement(
      "1",
      AgendaElementType.Other,
      "Networking Break (Amuse Bouche)",
      "Another opportunity to expand your network while enjoying the third course in the venue foyer.",
      "2021-11-03T19:10:00.000Z",
      "2021-11-03T19:25:00.000Z"
    ),
    new AgendaElement(
      "1",
      AgendaElementType.DinnerRound,
      "Dinner Round 2",
      "Enjoy the main dish and dessert while seated with another set of executives.",
      "2021-11-03T19:25:00.000Z",
      "2021-11-03T20:05:00.000Z",
      registration?.type !== "Executive Registration"
        ? dinnerRoundTwoDetail
        : dinnerRoundTwoDetail
/*        : undefined */
    ),
    new AgendaElement(
      "1",
      AgendaElementType.Other,
      "Coffee, Tea and Farewell",
      "Cap off the night with a coffee or tea while exchanging pleasantries and contact details with other attendees.",
      "2021-11-03T20:05:00.000Z",
      "2021-11-03T23:00:00.000Z"
    ),
  ];



  return (
    <Box className={classes.container}>

      <FirstLoginFlowDialog
           authToken={authToken || ""}
           registrations={registrations}
           profile={profile}
           event={event}
      />




     <Box
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Box style={{ flex: 1 }}>
          <Typography
            variant="h6"
            color="textPrimary"
            style={{ marginTop: 20, marginBottom: 20 }}
          >
             Agenda
          </Typography>
        </Box>

        <Box>
           <IconButton onClick={() => handleReload()}>
              <ReplayIcon/>
           </IconButton>
        </Box>
      </Box>



      {agenda.map((agendaElement: AgendaElement, index: number) => (
        <Accordion
          expanded={expandedSection === index}
          onChange={() => {
            setExpandedSection(expandedSection === index ? undefined : index);
          }}
          className={
            expandedSection === index
              ? classes.accordionExpanded
              : classes.accordionCollapsed
          }
        >
          <AccordionSummary
            style={{ display: "flex" }}
            expandIcon={<ExpandMoreIcon className={classes.icon} />}
          >
            <Box className={classes.row}>
              <Box style={{ flex: 1 }}>
                <Typography align="left" color="textPrimary">
                  <FormattedTime value={agendaElement.startTime} />
                  {" - "}
                  {index === agenda.length - 1 && "open end"}
                  {index < agenda.length - 1 && (
                    <FormattedTime value={agendaElement.endTime} />
                  )}
                </Typography>
              </Box>
              <Box className={classes.indicator} />
              <Box style={{ flex: 1 }}>
                {index < agenda.length - 1 && expandedSection !== index && (
                  <Box className={classes.line} />
                )}
                <Typography align="right" color="textPrimary">
                  {agendaElement.name}
                </Typography>
              </Box>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            {!agendaElement.detailComponent && (
              <Typography align="left" color="textSecondary">
                {agendaElement.description}
              </Typography>
            )}
            {agendaElement.detailComponent}
          </AccordionDetails>
        </Accordion>
      ))}

    </Box>
  );
};
