import React, {useEffect, useState} from "react";
import {createBrowserHistory} from "history";
import {Redirect, Route, Router, Switch} from "react-router-dom";
import {Box, Button, Fade, IconButton, ThemeProvider,} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {IntlProvider} from "react-intl";
import TuneIcon from "@material-ui/icons/TuneRounded";
import {darkTheme} from "./themes/default";
import {Registrations} from "./screens/registrations/Registrations";
import {Agenda} from "./screens/agenda/Agenda";
import {Feedback} from "./screens/feedback/Feedback";
import {Profile} from "./screens/profile/Profile";
import {Badge} from "./screens/badge/Badge";
import {FirstLoginFlow} from "./screens/firstLoginFlow/FirstLoginFlow";
import "./App.css";
import Logo from "../src/resources/logo.svg";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import MenuRoundedIcon from "@material-ui/icons/MenuRounded";
import ClearRoundedIcon from "@material-ui/icons/ClearRounded";
import {translations} from "./translations";
import {ThinkTanks} from "./screens/thinkTanks/ThinkTanks";
import {DynamicThinkTanks} from "./screens/dynamicThinkTanks/DynamicThinkTanks";
import {TestComponent} from "./screens/testComponent/TestComponent";
import {Priorities} from "./screens/priorities/Priorities";
import {Registration} from "./screens/registrations/Registration";
import {Error} from "./screens/error/Error";
import Config from "./config";
import {purgeStore, selectEnableRegistrationsMenu, selectRegistrationID, selectRegistrationType, selectError, selectToken, selectAuthenticationMethod, selectIsFetching} from "./screens/authentication/authenticationSlice";
import {useDispatch, useSelector} from "react-redux";
import {Authentication, AuthenticationQRCode} from "./screens/authentication/Authentication";
import {socket} from "./utils/webSocket";


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  contentContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  title: {
    flexGrow: 1,
  },
  spacer: {
    flex: 1,
  },
  routerContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    transition: theme.transitions.create("padding", {
      easing: theme.transitions.easing.sharp,
      duration: 200,
    }),
  },
  appBar: {
    minHeight: 36,
    padding: 0,
  },
  listItemIcon: {
    marginLeft: 4,
  },
  navigationBar: {
    color: "#ffffff",
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 20,
  },
  navigationTitle: {
    marginBottom: 20,
    "@media (max-height: 600px)": {
      display: "none",
    },
  },
  indicator: {
    display: "none",
  },
  illustration: {
    position: "absolute",
    top: 50,
    right: 40,
    transform: "scaleX(-1)",
    zIndex: 10000,
    "@media (max-width: 768px)": {
      display: "none",
    },
    "@media (max-height: 600px)": {
      display: "none",
    },
  },
  logo: {
    height: 15,
  },
  logoRow: {
    display: "flex",
    paddingBottom: 10,
    alignItems: "center",
    marginTop: 26,
  },
  highlightedMask: {
    borderRadius: 10,
  },
  queueVisible: {
    position: "fixed",
    bottom: 20,
    right: 20,
    zIndex: 10200,
    transition: "right 1s",
  },
  queueInvisible: {
    position: "fixed",
    bottom: 20,
    right: -340,
    zIndex: 10200,
    transition: "right 1s",
  },
  header: {
    display: "flex",
    alignItems: "center",
    paddingLeft: 10,
    paddingRight: 10,
    height: 68,
  },
  headerTopContainer: {
    height: 100,
    marginBottom: 40,
  },
  expandSidebarIcon: {
    transition: "transform 0.5s",
  },
  logoContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 20,
    marginTop: 44,
    overflow: "hidden",
  },
  logoTypography: {
    color: "#ffffff",
    letterSpacing: "0.02857em",
  },
  button: {
    color: "#ffffff",
  },
}));

const history = createBrowserHistory();

function App() {
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const tokenError = useSelector(selectError);
  const registrationID = useSelector(selectRegistrationID);
  const registrationType = useSelector(selectRegistrationType);
  const authenticationMethod = useSelector(selectAuthenticationMethod);
  const authenticationFetching = useSelector(selectIsFetching);
  const enableRegistrationsMenu = useSelector(selectEnableRegistrationsMenu);

  let theme = darkTheme;

  const classes = useStyles();

  const [showMenu, setShowMenu] = useState<boolean>(false);

  // TODO: enable language selection
  const messages = translations["en-US"].messages;

  const AuthenticationContainer = ({ children }: { children: any }) => {

    const token = useSelector(selectToken);
    const tokenError = useSelector(selectError);
    const authenticationMethod = useSelector(selectAuthenticationMethod);
    const authenticationFetching = useSelector(selectIsFetching);

    const urlSearchParams = new URLSearchParams(window.location.search);
    urlSearchParams.set("next", window.location.pathname);

    return token ? (
      children
    ) : (
      <Redirect
        to={{
          pathname: "/app/login/",
          search: urlSearchParams.toString(),
        }}
      />
    );
  };


//for the moment: comment out all other menu options
  const routes = [
    {
      label: "Agenda",
      path: "/app/agenda",
      icon: <TuneIcon />,
      component: Agenda,
      toplevel: true,
      execRoute: true,
    },
    {
      label: "Registrations",
      path: "/app/registrations",
      icon: <TuneIcon />,
      component: Registrations,
      toplevel: true,
      execRoute: enableRegistrationsMenu,
    },
    {
      label: "Registration",
      path: "/app/registrations/:registrationID",
      icon: <TuneIcon />,
      component: Registration,
      toplevel: false,
      execRoute: true,
    },
/*    {
      label: "FirstLoginFlow",
      path: "/app/first-login-flow",
      icon: <TuneIcon />,
      component: FirstLoginFlow,
      toplevel: true,
      execRoute: true,
    }, */
    {
      label: "Badge",
      path: "/app/badge",
      icon: <TuneIcon />,
      component: Badge,
      toplevel: true,
      execRoute: true,
    },
    {
      label: "Profile",
      path: "/app/profile",
      icon: <TuneIcon />,
      component: Profile,
      toplevel: true,
      execRoute: true,
    },
    {
      label: "ThinkTanks",
      path: "/app/think-tanks",
      icon: <TuneIcon />,
      component: ThinkTanks,
      toplevel: false,
      execRoute: true,
    },
    {
      label: "Priorities",
      path: "/app/priorities",
      icon: <TuneIcon />,
      component: Priorities,
      toplevel: false,
      execRoute: false,
    },
    /*
    {
      label: "Live Event",
      path: "/app/dynamic-think-tanks",
      icon: <TuneIcon />,
      component: DynamicThinkTanks,
      toplevel: true,
      execRoute: true,
    },*/
    {
      label: "Feedback",
      path: "/app/feedback",
      icon: <TuneIcon />,
      component: Feedback,
      toplevel: true,
      execRoute: true,
    },
/*    {
      label: "Test",
      path: "/app/test",
      icon: <TuneIcon />,
      component: TestComponent,
      toplevel: true,
      execRoute: true,
    },
*/
  ];

  useEffect(() => {
    const eventID = Config.getInstance().getEventID();
    const registrationID = Config.getInstance().getRegistrationID();
    const businessPartnerID = Config.getInstance().getBusinessPartnerID() || null;


    if (!eventID || (!registrationID && !businessPartnerID)) {
      history.push("/error");
    }
  }, []);


  const logout = () => {
    setShowMenu(false);
    dispatch(purgeStore());
    history.push({
      pathname: "/app/login/",
      search: window.location.search,
    });
  };

  return (
    <div className="App">
      <IntlProvider
        messages={messages}
        locale={"en-US"}
        defaultLocale="en-US"
        onError={() => undefined}
      >
        <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Box className={classes.contentContainer}>
              <Box
                className={classes.header}
                style={{ borderBottom: "1px solid #2D3038" }}
              >
                <img
                  alt="logo"
                  className={classes.logo}
                  src={Logo}
                  style={{ marginLeft: 10 }}
                />
                <Box className={classes.spacer} />
                {window.location.pathname !== "/app/think-tanks" &&
                 window.location.pathname !== "/app/priorities" &&
                <IconButton
                  className={classes.button}
                  onClick={() => setShowMenu(!showMenu)}
                >
                  <MenuRoundedIcon />
                </IconButton>
}
                <Fade in={showMenu}>
                  <Box
                    style={{
                      zIndex: 10000,
                      position: "fixed",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      flexDirection: "column",
                      backgroundColor: "#f5f6fa",
                      padding: 10,
                    }}
                  >
                    <Box className={classes.header}>
                      <Box className={classes.spacer} />
                      <IconButton
                        className={classes.button}
                        onClick={() => setShowMenu(!showMenu)}
                      >
                        <ClearRoundedIcon style={{ color: "#25262E" }} />
                      </IconButton>
                    </Box>
                    {registrationType !== "Executive Registration" ?
                      routes
                      .filter((route) => route.toplevel)
                      .map((route, index) => (
                        <Button
                          key={index}
                          style={{ padding: 10, color: "#25262E" }}
                          onClick={() => {
                            setShowMenu(false);
                            if(route.label === "Live Event"){
                              socket.connect()
                            } else {
                              socket.disconnect()
                            }
                            history.push({
                              pathname: route.path,
                              search: window.location.search,
                            });
                          }}
                        >
                          {route.label}
                        </Button>
                      ))
                      :
                      routes
                      .filter((route) => route.toplevel)
                      .filter((route) => route.execRoute)
                      .map((route, index) => (
                        <Button
                          key={index}
                          style={{ padding: 10, color: "#25262E" }}
                          onClick={() => {
                            setShowMenu(false);
                            if(route.label === "Live Event"){
                              socket.connect()
                            } else {
                              socket.disconnect()
                            }
                            history.push({
                              pathname: route.path,
                              search: window.location.search,
                            });
                          }}
                        >
                          {route.label}
                        </Button>
                      ))
                    }
                    <Button
                      color="secondary"
                      style={{ padding: 10 }}
                      onClick={() => logout()}
                    >
                      Logout
                    </Button>
                  </Box>
                </Fade>
              </Box>
              <main className={classes.routerContainer}>
                <Router history={history}>
                  <Switch>
                    {routes.map((route, index) => (
                      <Route
                        key={index}
                        exact
                        path={route.path}
                        render={() => {

                          if (registrationID && Config.getInstance().getBusinessPartnerID()) {

                            const urlSearchParams = new URLSearchParams(
                                window.location.search
                            );

                            urlSearchParams.delete("business_partner_id");
//                            urlSearchParams.delete("access_token");
                            urlSearchParams.set(
                                "registration_id",
                                registrationID || ""
                            );

                            history.push({
                              pathname: route.path,
                              search: urlSearchParams.toString(),
                            });
                          }


                          return (
                            <AuthenticationContainer>
                              <route.component />
                            </AuthenticationContainer>
                          );
                        }}
                      />
                    ))}
                    <Route
                      path="/app/login/"
                      render={() => {
                        const urlSearchParams = new URLSearchParams(
                          window.location.search
                        );
                        const redirectURL = urlSearchParams.get("next") || "";
                        const accessTokenQRCode = urlSearchParams.get("access_token") || null;

                        urlSearchParams.delete("next");
                        urlSearchParams.delete("business_partner_id");
//                        urlSearchParams.delete("access_token");
                        urlSearchParams.set(
                          "registration_id",
                          registrationID || ""
                        );

                        return token && registrationID ? (
                          <Redirect
                            to={{
                              pathname: redirectURL
                                ? redirectURL
                                : "/app/badge/",
                              search: urlSearchParams.toString(),
                            }}
                          />
                        )
                        :
                        accessTokenQRCode &&
                          !tokenError &&
                          authenticationMethod !=='password' &&
                          !authenticationFetching
                           ? (
                        <AuthenticationQRCode />
                      )
                      :
                        (
                          <Authentication />
                        );
                      }}
                    />
                    <Route path="/error/" component={Error} />
                    <Redirect
                      from="/"
                      to={{
                        pathname: routes[0].path,
                        search: history.location.search,
                      }}
                    />
                  </Switch>
                </Router>
              </main>
            </Box>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </IntlProvider>
    </div>
  );
}

export default App;
